import React from "react";

import moment from "moment";

class EventsTicker extends React.Component {
  constructor(props) {
    super(props);

    this.frameRef = React.createRef();
    this.wrapperRef = React.createRef();

    this.dateIndex = this.dateIndex.bind(this);
    this.eventClasses = this.eventClasses.bind(this);
    this.scrollTicker = this.scrollTicker.bind(this);
  }

  componentDidUpdate() {
    if (this.props.animationProgress >= 0) {
      this.scrollTicker();
    }
  }

  dateIndex(date) {
    let dateIndex = null;

    this.props.events.forEach((event, idx) => {
      if (event['date'] === date) {
        dateIndex = idx;
      }
    });

    return dateIndex;
  }

  eventClasses(date) {
    let eventClasses = 'event-group ';
    const dateIndex = this.dateIndex(date);

    eventClasses += (dateIndex === this.props.animationProgress) ? 'highlight ' : '';

    return eventClasses;
  }

  scrollTicker() {
    const frame = this.frameRef.current;
    const wrapper = this.wrapperRef.current;
    const currentEvent = wrapper.querySelectorAll('.event-group')[this.props.animationProgress];

    if (currentEvent) {
      const eventTop = currentEvent.offsetTop;

      if (eventTop < frame.offsetHeight / 2) {
        wrapper.style.top = 0;
      } else if (eventTop > wrapper.offsetHeight - (frame.offsetHeight / 2)) {
        wrapper.style.top = `-${wrapper.offsetHeight - frame.offsetHeight}px`;
      } else {
        wrapper.style.top = '-' +
                            (currentEvent.offsetTop +
                                (currentEvent.offsetHeight / 2) -
                                (frame.offsetHeight / 2)
                            ) +
                            'px';
      }
    }
  }

  render() {
    return (
      <div ref={this.frameRef} className="event-display">
        <div ref={this.wrapperRef} className="event-contents">
          {this.props.events.map((event, idx) => (
            <div className={this.eventClasses(event.date)} key={idx}>
              <span className="date-line">
                {moment(event.date).format("dddd MMMM D")}
              </span>
              <span className="location-line">
                {event.city}
              </span>
              <span className="note-line">
                {event.note}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default EventsTicker;