import React from "react";

import { marked } from "marked";

export default (props) => {
  let formattedText = marked(props.text);
  formattedText = formattedText.replace('<p>', '');
  formattedText = formattedText.replace('</p>', '');

  const noteContents = 
          (<span dangerouslySetInnerHTML={{ __html: formattedText }} />);
  
  return (
    <li id={`note-${props.number}`}><span className="contents">
      {noteContents}
      <a className="back-link" href={`#note-${props.number}-link`}>⏎</a>
    </span></li>
  );
}