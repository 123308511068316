import React from "react";

import CloseButton from "./close-button";

class PopupJukebox extends React.Component {
  constructor(props) {
    super(props);

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);

    this.state = { hovered: false };
  }

  handleMouseOver() {
    this.setState({ hovered: true });
  }

  handleMouseOut() {
    this.setState({ hovered: false });
  }

  render() {
    if (this.props.video) {
      return (
        <div className="popup-player"
             onMouseOver={this.handleMouseOver}
             onMouseOut={this.handleMouseOut}>
          <div className="frame-wrap">
            <iframe 
              title={this.props.video.videoId}
              src={`https://www.youtube.com/embed/${this.props.video.videoId}?modestbranding=1&showinfo=0&rel=0&fs=0&controls=0&playsinline=1&autoplay=1`} />
          </div>
          <p className="caption">
            {this.props.video.caption}
          </p>
          <CloseButton narrative={this.props.narrative}
                       playerHovered={this.state.hovered} />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default PopupJukebox;