import React from "react";

import Footnote from "./footnote";

export default (props) => {
  if (props.footnotes.length > 0) {
    return (
      <div className="footnotes-block">
        <h2>Notes</h2>
        <ol>
          {props.footnotes.map((note, idx) => (
            <Footnote text={note.text} number={idx+1} key={idx} />
          ))}
        </ol>
      </div>
    );
  }

  return null;
}