import React from 'react';

class DonutChart extends React.Component {
  constructor(props) {
    super(props);

    this.chartRef = React.createRef();
    this.wrapperRef = React.createRef();

    this.drawD3 = this.drawD3.bind(this);
  }

  componentDidMount() {
    this.drawD3();

    window.addEventListener('resize', e => {
      this.drawD3();
    });
  }

  componentDidUpdate() {
    this.drawD3();
  }

  drawD3() {
    const d3 = require('d3');

    const pie = d3.pie()
                  .value((d) => d.percent)
                  .sort(null)
                  .padAngle(.03);
    
    const w = this.wrapperRef.current.offsetWidth * .75;
    const h = w;

    const outerRadius = w / 2;
    const innerRadius = w / 3;

    const color = ['#e9ecef', '#82c91e', '#12b886', '#228be6'];
    // ['Gray 2', 'Lime 6', 'Teal 6', 'Blue 6']
    // https://yeun.github.io/open-color


    const arc = d3.arc()
                  .outerRadius(outerRadius)
                  .innerRadius(innerRadius);
    
    const chart = d3.select(this.chartRef.current);

    let svg = chart.select('svg');
    if (!svg._groups[0][0]) {
      svg = chart.append('svg').attr('width', w).attr('height', h).attr('class', 'shadow')
                 .attr('preserveAspectRatio', 'xMinYMin meet')
                 .attr('viewBox', `0 0 ${w} ${h}`)
                 .append('g').attr('transform', `translate(${w/2}, ${h/2})`);
    } else {
      // svg.attr('width', w).attr('height', h).
      //     select('g').
      //     attr('width', w).attr('height', h).
      //     attr('transform', `translate(${w/2}, ${h/2})`);
    }

    const path = svg.selectAll('path')
                    .data(pie(this.props.dataset.reverse()))
                    .enter()
                    .append('path').attr('d', arc).attr('fill', (d,i) => color[i]);
  }

  render() {
    return (
      <div className="econ-viz-donut-chart-wrapper" ref={this.wrapperRef}>
        <div id={this.props.chartId} ref={this.chartRef} class="chart-container"></div>
      </div>
    );
  }
}

export default DonutChart;