import React from "react";

function handleClick(e, video, props) {
  e.preventDefault();

  props.launchPopupJukebox(video);
}

export default (props) => {
  const video = {
    caption: props.content.caption,
    service: props.content.service,
    videoId: props.content.videoId,
  };

  return (
    <div className="popup-jukebox-link-block">
      <a href={props.content.clickthrough} className="popup-jukebox-link" 
                  onClick={(e) => {handleClick(e, video, props)}}>
        <i className="fas fa-volume-up" /><br />
        {video.caption}
      </a>
    </div>
  )
}