import React from 'react';

import "./demographics-1940s.scss";

import GroupedBarChart from './grouped-bar-chart';
import LineChart from './line-chart';
import StackedBarChart from './stacked-bar-chart';

export const Demographics1940sChart1 = (props) => {
  return (
    <div className="demographics-viz-chart demographics-viz-chart-1">
      <div className="header-line">
        Population Growth, 1900-2000
      </div>
      <LineChart />
      <div className="credit-line">
        Source: <a href="https://library.austintexas.gov/ahc/everything-austin-population-statistics">Austin Public Library</a>
      </div>
    </div>
  );
};

export const Demographics1940sChart2 = (props) => {
  return (
    <div className="demographics-viz-chart demographics-viz-chart-2">
      <div className="header-line">
        Percentages of Population Growth, 1900-2000
      </div>
      <StackedBarChart />
      <div className="credit-line">
        Source: <a href="https://library.austintexas.gov/ahc/everything-austin-population-statistics">Austin Public Library</a>
      </div>
    </div>
  );
};

export const Demographics1940sChart3 = (props) => {
  return (
    <div className="demographics-viz-chart demographics-viz-chart-3">
      <div className="demographics-viz-chart-3__cell">
        <div className="header-line">
          1920-1950 Income Numbers
          <span className="subhead">in Period Dollars</span>
        </div>
        <GroupedBarChart
          minY='500'
          maxY='2600'
          wageData={[
            { year: 1920, manufacturing: 937.71, retail: null, service: null, household: null },
            { year: 1930, manufacturing: 1228.27, retail: 1233.33, service: null, household: null },
            { year: 1939, manufacturing: 945.84, retail: 881.84, service: 775.15, household: null },
            { year: 1950, manufacturing: null, retail: null, service: null, household: 2126 },
          ]}
        />
        <div className="credit-line">
          Source: Steven Manson, Jonathan Schroeder, David Van Riper, and Steven Ruggles.<br />
          IPUMS National Historical Geographic Information System: Version 14.0<br />
          [Database]. Minneapolis, MN: IPUMS. 2019.<br />
          http://doi.org/10.18128/D050.V14.0
        </div>
      </div>
      <div className="demographics-viz-chart-3__cell">
        <div className="header-line">
          1920-1950 Income Numbers
          <span className="subhead">in 2020 Dollars</span>
        </div>
        <GroupedBarChart
          minY='5000'
          maxY='26000'
          wageData={[
            { year: 1920, manufacturing: 12651.12, retail: null, service: null, household: null },
            { year: 1930, manufacturing: 18703.32, retail: 18780.37, service: null, household: null },
            { year: 1939, manufacturing: 17591.81, retail: 16401.47, service: 14417.13, household: null },
            { year: 1950, manufacturing: null, retail: null, service: null, household: 23556.80 }
          ]}
          suppressLegend={ true }
        />
        <div className="credit-line">
          Source: U.S. Bureau of Labor Statisics, <a href="https://www.bls.gov/data/inflation_calculator.htm">CPI Inflation Calculator</a>
        </div>
      </div>
    </div>
  );
};
