import React from "react";

import moment from "moment";

import { Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import EventsTicker from "./events-ticker";
import MovieControl from "./movie-control";
import Calendar from "./calendar";

import mapPositions from "./map-positions";
import calendarGrids from "./calendar-grids";

import "./count-basie-1941.scss";

class CountBasie1941 extends React.Component {
  constructor(props) {
    super(props);

    this.animationFrames = [];
    this.originalPointIds = [];

    this.mapRef = React.createRef();
    this.mapWrapperRef = React.createRef();

    this.buildMovie = this.buildMovie.bind(this);
    this.checkAnimation = this.checkAnimation.bind(this);
    this.clearMap = this.clearMap.bind(this);
    this.drawOriginalPoints = this.drawOriginalPoints.bind(this);
    this.fitMap = this.fitMap.bind(this);
    this.jumpToDate = this.jumpToDate.bind(this);
    this.onMovieControlClick = this.onMovieControlClick.bind(this);
    this.showPopup = this.showPopup.bind(this);

    this.state = {
      animationProgress: -1,
      animationActive: false,
    };
  }

  buildMovie() {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    const mapStates = mapPositions.map((event, idx, self) => {
      let positions = [];
      let edges = [];

      for (let i = 0; i <= idx; i++) {
        if (self[i]['lat'] && self[i]['lon']) {
          positions.push([self[i]['lat'], self[i]['lon']]);
        }
      }

      for (let i = 0; i <= idx; i++) {
        if (self[i]['lat'] && self[i]['lon']) {
          for (let j = i + 1; j <= idx; j++) {
            if (self[j]['lat'] && self[j]['lon']) {
              edges.push([
                [self[i]['lat'], self[i]['lon']],
                [self[j]['lat'], self[j]['lon']]
              ]);
              break;
            }
          }
        }
      }

      return { positions, edges };
    });

    return mapPositions.map((event, idx) => {
      const showPopup = this.showPopup;

      const frame = function() {
        mapStates[idx].positions.forEach(dot => {
          L.circleMarker(dot, {color: '#fd7e14', stroke: false, radius: 7, fillOpacity: .8}).addTo(map);
        });
        mapStates[idx].edges.forEach(edge => {
          L.polyline(edge, {color: '#fd7e14', opacity: .8}).addTo(map);
        });

        if (event['city']) {
          showPopup(event);
        } else {
          map.closePopup();
        }
      };

      return frame;
    });
  }

  checkAnimation() {
    if (this.state.animationActive) {
      if (this.state.animationProgress < this.animationFrames.length - 1) {
        this.clearMap();
        this.setState({
          animationProgress: this.state.animationProgress + 1,
        });
        this.animationFrames[this.state.animationProgress]();
      } else {
        this.setState({
          animationActive: false,
          animationProgress: -1,
        });
      }
    }
  }

  clearMap() {
    const map = this.mapRef.current.leafletElement;

    for (let i in map._layers) {
      if (!this.originalPointIds.includes(map._layers[i]._leaflet_id)) {
        if (map._layers[i]._path !== undefined) {
          try {
            map.removeLayer(map._layers[i]);
          }
          catch(e) {
            console.log("problem with " + e + map._layers[i]);
          }
        }
      }
    }
  }

  componentDidMount() {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    this.originalPointIds = this.drawOriginalPoints();

    this.fitMap();

    this.animationFrames = this.buildMovie();

    setInterval(this.checkAnimation, 1500);
  }

  drawOriginalPoints() {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    const allPoints = mapPositions.filter(point => point['lat'] && point['lon']);
    
    const markers = allPoints.map(point => {
      return L.circleMarker(point, {color: '#fd7e14', stroke: false, radius: 5, fillOpacity: .5}).addTo(map);
    });
    
    return markers.map(marker => marker._leaflet_id);
  }

  fitMap() {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    const allPoints = mapPositions.filter(point => point['lat'] && point['lon']);
    
    map.fitBounds(L.polyline(allPoints).getBounds());
  }

  jumpToDate(date) {
    const wrapper = this.mapWrapperRef.current;
    let dateIndex = null;

    mapPositions.forEach((event, idx) => {
      if (event['date'] === date) {
        dateIndex = idx;
      }
    });

    if (dateIndex) {
      this.setState({
        animationActive: false,
        animationProgress: dateIndex,
      });
      this.clearMap();
      this.animationFrames[dateIndex]();
      wrapper.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onMovieControlClick(e) {
    e.preventDefault();
    this.setState({animationActive: !this.state.animationActive});
  }

  showPopup(event) {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    let content = `<span class="location">${event.city}</span>`;
    content += `<span class="date">${moment(event.date).format("dddd MMMM D")}</span>`;
    if (event.note.length > 0) {
      content += `<span class="note">${event.note}</span>`;
    }

    L.popup({ closeButton: false })
      .setLatLng([event.lat, event.lon])
      .setContent(content)
      .openOn(map);
  }

  render() {
    if (typeof window !== 'undefined') {
      return (
        <div ref={this.mapWrapperRef} className="cbasie-tour-wrapper">
          <div className="display-wrapper">
            <div className="map-wrapper">
              <div className="movie-control">
                <MovieControl
                  onClick={this.onMovieControlClick}
                  animationActive={this.state.animationActive}
                />
              </div>
              <Map ref={this.mapRef}
                className="map"
                dragging={false}
                boxZoom={false}
                zoomControl={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                touchZoom={false}
              >
                <TileLayer
                  url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                />
              </Map>
            </div>
            <EventsTicker 
              events={mapPositions}
              animationProgress={this.state.animationProgress}
            />
          </div>
          
          <div className="calendar-wrapper">
            {(calendarGrids.map((calendar, idx) => (
              <Calendar key={idx}
                calendar={calendar}
                events={mapPositions}
                animationProgress={this.state.animationProgress}
                jumpToDate={this.jumpToDate}
              />
            )))}
          </div>
        </div>
      );
    }

    return (
      <div ref={this.mapWrapperRef} className="cbasie-tour-wrapper"></div>
    );
  }
}

export default CountBasie1941;