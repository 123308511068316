export const allAnimationStates = {
  "ballrooms": [
    {
      step: "Scroll First Text",
      type: "text",
      content: "The changes in the geography and type of live music venues is striking when you plot it out cartographically.",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "driskill-hotel": "hide",
        "sfa-hotel": "hide",
        "womens-clubs": "hide",
        "austin-country-club": "hide",
        "varsity-inn": "hide",
        "the-tower": "hide",
        "uncle-toms": "hide",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "long",
    },
    {
      step: "Scroll Second Text",
      type: "text",
      content: "During most of the 1930s, the main spots to hear live popular music, besides informal events in homes, were in large, exclusive ballrooms and in <a href=\"/venues/gregory-gym\">Gregory Gym</a> on campus.",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "driskill-hotel": "hide",
        "sfa-hotel": "hide",
        "womens-clubs": "hide",
        "austin-country-club": "hide",
        "varsity-inn": "hide",
        "the-tower": "hide",
        "uncle-toms": "hide",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "long",
    },
    {
      step: "Show Gregory Gym",
      type: "location",
      markerType: "dot",
      reference: "gregory-gym",
      label: "Gregory Gym<br />(UT Campus)",
      labelTop: "50%",
      labelLeft: "55%",
      markerTop: "52%",
      markerLeft: "47%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "driskill-hotel": "hide",
        "sfa-hotel": "hide",
        "womens-clubs": "hide",
        "austin-country-club": "hide",
        "varsity-inn": "hide",
        "the-tower": "hide",
        "uncle-toms": "hide",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Texas Union",
      type: "location",
      markerType: "dot",
      reference: "texas-union",
      labelTop: "53%",
      labelLeft: "40%",
      markerTop: "52%",
      markerLeft: "42.5%",
      label: "Texas Union<br />(UT Campus)",
      visibility: {
        "gregory-gym": "highlight",
        "texas-union": "hide",
        "driskill-hotel": "hide",
        "sfa-hotel": "hide",
        "womens-clubs": "hide",
        "austin-country-club": "hide",
        "varsity-inn": "hide",
        "the-tower": "hide",
        "uncle-toms": "hide",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Driskill Hotel",
      type: "location",
      markerType: "dot",
      reference: "driskill-hotel",
      label: "Driskill Hotel",
      labelTop: "63%",
      labelLeft: "48%",
      markerTop: "62%",
      markerLeft: "46%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "highlight",
        "driskill-hotel": "hide",
        "sfa-hotel": "hide",
        "womens-clubs": "hide",
        "austin-country-club": "hide",
        "varsity-inn": "hide",
        "the-tower": "hide",
        "uncle-toms": "hide",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Show SFA Hotel",
      type: "location",
      markerType: "dot",
      reference: "sfa-hotel",
      label: "Stephen F. Austin Hotel",
      labelTop: "58.25%",
      labelLeft: "53%",
      markerTop: "61%",
      markerLeft: "45.5%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "highlight",
        "sfa-hotel": "hide",
        "womens-clubs": "hide",
        "austin-country-club": "hide",
        "varsity-inn": "hide",
        "the-tower": "hide",
        "uncle-toms": "hide",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Women’s Clubs",
      type: "location",
      markerType: "dot",
      reference: "womens-clubs",
      label: "Texas Federation<br />of Women’s Clubs",
      labelTop: "47%",
      labelLeft: "29%",
      markerTop: "51.5%",
      markerLeft: "36%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "highlight",
        "womens-clubs": "hide",
        "austin-country-club": "hide",
        "varsity-inn": "hide",
        "the-tower": "hide",
        "uncle-toms": "hide",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Austin Country Club",
      type: "location",
      markerType: "dot",
      reference: "austin-country-club",
      label: "Austin Country Club",
      labelTop: "39.5%",
      labelLeft: "53%",
      markerTop: "42%",
      markerLeft: "52.5%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "highlight",
        "austin-country-club": "hide",
        "varsity-inn": "hide",
        "the-tower": "hide",
        "uncle-toms": "hide",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Scroll Third Text",
      type: "text",
      content: "The majority of dances at these venues were put on by and for university students.",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "hide",
        "the-tower": "hide",
        "uncle-toms": "hide",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "long",
    },
    {
      step: "Scroll Fourth Text",
      type: "text",
      content: "Night Clubs began to appear in and around Austin during the latter half of the Depression. Clubs like the Tower and the <a href=\"/venues/avalon-dinner-club\">Avalon</a> were still fairly elite venues, however. Featuring floor shows and fine dining, they primarily catered to university students and wealthy patrons.",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "hide",
        "the-tower": "hide",
        "uncle-toms": "hide",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "long",
    },
    {
      step: "Show Varsity Inn",
      type: "location",
      markerType: "dot",
      reference: "varsity-inn",
      label: "Varsity Inn<br />(Opened 1936)",
      color: "grape",
      labelTop: "16.5%",
      labelLeft: "50%",
      markerTop: "21%",
      markerLeft: "46%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "hide",
        "the-tower": "hide",
        "uncle-toms": "hide",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Show The Tower",
      type: "location",
      markerType: "dot",
      reference: "the-tower",
      label: "The Tower<br />(Opened 1936)",
      color: "grape",
      labelTop: "70%",
      labelLeft: "43%",
      markerTop: "69%",
      markerLeft: "44%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "highlight",
        "the-tower": "hide",
        "uncle-toms": "hide",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Uncle Tom's",
      type: "location",
      markerType: "dot",
      reference: "uncle-toms",
      label: "Uncle Tom’s<br />(Opened 1936)",
      color: "grape",
      labelTop: "94%",
      labelLeft: "32%",
      markerTop: "98.5%",
      markerLeft: "35.5%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "show",
        "the-tower": "highlight",
        "uncle-toms": "hide",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Villa Rosa",
      type: "location",
      markerType: "dot",
      reference: "villa-rosa",
      label: "Villa Rosa<br />(Opened 1936)",
      color: "grape",
      labelTop: "88.5%",
      labelLeft: "48%",
      markerTop: "89.5%",
      markerLeft: "39%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "show",
        "the-tower": "show",
        "uncle-toms": "highlight",
        "villa-rosa": "hide",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Show The Avalon",
      type: "location",
      markerType: "dot",
      reference: "the-avalon",
      label: "The Avalon<br />(Opened 1937)",
      color: "grape",
      labelTop: "23%",
      labelLeft: "44%",
      markerTop: "22%",
      markerLeft: "45.75%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "show",
        "the-tower": "show",
        "uncle-toms": "show",
        "villa-rosa": "highlight",
        "the-avalon": "hide",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Show La Conga",
      type: "location",
      markerType: "dot",
      reference: "la-conga",
      label: "La Conga<br />(Opened 1938)",
      color: "grape",
      labelTop: "80%",
      labelLeft: "53%",
      markerTop: "80%",
      markerLeft: "44%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "show",
        "the-tower": "show",
        "uncle-toms": "show",
        "villa-rosa": "show",
        "the-avalon": "highlight",
        "la-conga": "hide",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Rocky Ridge Tavern",
      type: "location",
      markerType: "arrow",
      arrowDirection: "turn-nw",
      reference: "rocky-ridge-tavern",
      label: "To Rocky Ridge Tavern<br />(Opened 1938)",
      color: "grape",
      labelTop: "6%",
      labelLeft: "17%",
      markerTop: "4%",
      markerLeft: "22%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "show",
        "the-tower": "show",
        "uncle-toms": "show",
        "villa-rosa": "show",
        "the-avalon": "show",
        "la-conga": "highlight",
        "rocky-ridge-tavern": "hide",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Scroll Fifth Text",
      type: "text",
      content: "By the end of the 1930s, these clubs had an important effect on Austin's music scene. Drawing audiences away from campus's “Germans,” they forced the university to reduce its number of dances, setting the stage for the coming rise of Honky Tonks and small dance halls.",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "show",
        "the-tower": "show",
        "uncle-toms": "show",
        "villa-rosa": "show",
        "the-avalon": "show",
        "la-conga": "show",
        "rocky-ridge-tavern": "highlight",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "long",
    },
    {
      step: "Scroll Sixth Text",
      type: "text",
      content: "The main non-UT affiliated dance space was the American Legion building by Lake Austin, where A.L. members gathered to dance to the <a href=\"/artists/kayo-cloud-and-his-orchestra\">Kayo Cloud Orchestra</a> throughout the 1930s.",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "show",
        "the-tower": "show",
        "uncle-toms": "show",
        "villa-rosa": "show",
        "the-avalon": "show",
        "la-conga": "show",
        "rocky-ridge-tavern": "show",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "long",
    },
    {
      step: "Show American Legion",
      type: "location",
      markerType: "dot",
      reference: "american-legion",
      label: "American Legion",
      color: "green",
      labelTop: "59%",
      labelLeft: "20%",
      markerTop: "62%",
      markerLeft: "23%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "show",
        "the-tower": "show",
        "uncle-toms": "show",
        "villa-rosa": "show",
        "the-avalon": "show",
        "la-conga": "show",
        "rocky-ridge-tavern": "show",
        "american-legion": "hide",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Seventh Text",
      type: "text",
      content: "The only place that regularly featured working class, down home Hillbilly music and early Western Swing during this decade was north of Austin: <a href=\"/venues/dessau-hall\">Dessau Hall</a>.",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "show",
        "the-tower": "show",
        "uncle-toms": "show",
        "villa-rosa": "show",
        "the-avalon": "show",
        "la-conga": "show",
        "rocky-ridge-tavern": "show",
        "american-legion": "highlight",
        "dessau-hall": "hide",
      },
      duration: "long"
    },
    {
      step: "Show Dessau Hall",
      type: "location",
      markerType: "arrow",
      arrowDirection: "turn-ne",
      reference: "dessau-hall",
      label: "To Dessau Hall",
      color: "red",
      labelTop: "17%",
      labelLeft: "83%",
      markerTop: "16%",
      markerLeft: "75%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "show",
        "the-tower": "show",
        "uncle-toms": "show",
        "villa-rosa": "show",
        "the-avalon": "show",
        "la-conga": "show",
        "rocky-ridge-tavern": "show",
        "american-legion": "highlight",
        "dessau-hall": "hide",
      },
      duration: "short",
    },
    {
      step: "Dessau Hall Visible",
      type: "empty",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "show",
        "the-tower": "show",
        "uncle-toms": "show",
        "villa-rosa": "show",
        "the-avalon": "show",
        "la-conga": "show",
        "rocky-ridge-tavern": "show",
        "american-legion": "show",
        "dessau-hall": "highlight",
      },
      duration: "short",
    },
    {
      step: "Last Stage",
      type: "empty",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "driskill-hotel": "show",
        "sfa-hotel": "show",
        "womens-clubs": "show",
        "austin-country-club": "show",
        "varsity-inn": "show",
        "the-tower": "show",
        "uncle-toms": "show",
        "villa-rosa": "show",
        "the-avalon": "show",
        "la-conga": "show",
        "rocky-ridge-tavern": "show",
        "american-legion": "show",
        "dessau-hall": "show",
      },
      duration: "long",
    },
  ],
  "honkytonks": [
    {
      step: "Scroll First Text",
      type: "text",
      content: "UT continued to put on big band concerts at Gregory and the <a href=\"/venues/texas-union\">Texas Union</a>.",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "the-barn": "hide",
        "the-owl-club": "hide",
        "the-star-club": "hide",
        "skyline-club": "hide",
        "the-plantation": "hide",
        "prices-hall": "hide",
        "threadgills": "hide",
        "top-hat": "hide",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "long",
    },
    {
      step: "Show Gregory Gym",
      type: "location",
      markerType: "dot",
      reference: "gregory-gym",
      label: "Gregory Gym",
      labelTop: "53%",
      labelLeft: "53%",
      markerTop: "56%",
      markerLeft: "48.25%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "the-barn": "hide",
        "the-owl-club": "hide",
        "the-star-club": "hide",
        "skyline-club": "hide",
        "the-plantation": "hide",
        "prices-hall": "hide",
        "threadgills": "hide",
        "top-hat": "hide",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Texas Union",
      type: "location",
      markerType: "dot",
      reference: "texas-union",
      label: "Texas Union",
      labelTop: "57%",
      labelLeft: "42%",
      markerTop: "56%",
      markerLeft: "47.25%",
      visibility: {
        "gregory-gym": "highlight",
        "texas-union": "hide",
        "the-barn": "hide",
        "the-owl-club": "hide",
        "the-star-club": "hide",
        "skyline-club": "hide",
        "the-plantation": "hide",
        "prices-hall": "hide",
        "threadgills": "hide",
        "top-hat": "hide",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Scroll Second Text",
      type: "text",
      content: "There were considerably fewer dances put on directly by the university after 1942. Clubs, fraternities, and sororities continued to hold frequent formals and social dances with orchestras on and around campus, however.",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "highlight",
        "the-barn": "hide",
        "the-owl-club": "hide",
        "the-star-club": "hide",
        "skyline-club": "hide",
        "the-plantation": "hide",
        "prices-hall": "hide",
        "threadgills": "hide",
        "top-hat": "hide",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "long",
    },
    {
      step: "Scroll Third Text",
      type: "text",
      content: " By the mid-1940s, live music life was much more dispersed, taking place at lots of little bars and night clubs around town. Like many of their high class predecessors, these clubs and Honky Tonks were outside the city limits.",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "hide",
        "the-owl-club": "hide",
        "the-star-club": "hide",
        "skyline-club": "hide",
        "the-plantation": "hide",
        "prices-hall": "hide",
        "threadgills": "hide",
        "top-hat": "hide",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "long",
    },
    {
      step: "Show The Barn",
      type: "location",
      markerType: "dot",
      reference: "the-barn",
      label: "The Barn",
      color: "red",
      labelTop: "9%",
      labelLeft: "21.5%",
      markerTop: "10%",
      markerLeft: "15%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "hide",
        "the-owl-club": "hide",
        "the-star-club": "hide",
        "skyline-club": "hide",
        "the-plantation": "hide",
        "prices-hall": "hide",
        "threadgills": "hide",
        "top-hat": "hide",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show The Owl Club",
      type: "location",
      markerType: "dot",
      reference: "the-owl-club",
      label: "The Owl Club",
      color: "red",
      labelTop: "30.5%",
      labelLeft: "43%",
      markerTop: "33%",
      markerLeft: "37%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "highlight",
        "the-owl-club": "hide",
        "the-star-club": "hide",
        "skyline-club": "hide",
        "the-plantation": "hide",
        "prices-hall": "hide",
        "threadgills": "hide",
        "top-hat": "hide",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show The Star Club",
      type: "location",
      markerType: "arrow",
      arrowDirection: "turn-nw",
      reference: "the-star-club",
      label: "To The Star Club",
      color: "red",
      labelTop: "5%",
      labelLeft: "20.5%",
      markerTop: "6%",
      markerLeft: "10%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "highlight",
        "the-star-club": "hide",
        "skyline-club": "hide",
        "the-plantation": "hide",
        "prices-hall": "hide",
        "threadgills": "hide",
        "top-hat": "hide",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Skyline Club",
      type: "location",
      markerType: "dot",
      reference: "skyline-club",
      label: "Skyline Club",
      color: "red",
      labelTop: "38%",
      labelLeft: "35%",
      markerTop: "37%",
      markerLeft: "39%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "highlight",
        "skyline-club": "hide",
        "the-plantation": "hide",
        "prices-hall": "hide",
        "threadgills": "hide",
        "top-hat": "hide",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show The Plantation",
      type: "location",
      markerType: "dot",
      reference: "the-plantation",
      label: "The Plantation",
      color: "red",
      labelTop: "34%",
      labelLeft: "48%",
      markerTop: "35%",
      markerLeft: "39.5%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "highlight",
        "the-plantation": "hide",
        "prices-hall": "hide",
        "threadgills": "hide",
        "top-hat": "hide",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Price’s Hall",
      type: "location",
      markerType: "dot",
      reference: "prices-hall",
      label: "Price’s Hall",
      color: "red",
      labelTop: "12%",
      labelLeft: "24.5%",
      markerTop: "13%",
      markerLeft: "17%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "highlight",
        "prices-hall": "hide",
        "threadgills": "hide",
        "top-hat": "hide",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Threadgill’s",
      type: "location",
      markerType: "dot",
      reference: "threadgills",
      label: "Threadgill’s",
      color: "red",
      labelTop: "45%",
      labelLeft: "34%",
      markerTop: "46%",
      markerLeft: "40.5%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "highlight",
        "threadgills": "hide",
        "top-hat": "hide",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Top Hat",
      type: "location",
      markerType: "dot",
      reference: "top-hat",
      label: "Top Hat",
      color: "red",
      labelTop: "72.5%",
      labelLeft: "60%",
      markerTop: "73.5%",
      markerLeft: "54.5%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "highlight",
        "top-hat": "hide",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show El Morocco Club",
      type: "location",
      markerType: "dot",
      reference: "el-morocco-club",
      label: "El Morocco Club",
      color: "red",
      labelTop: "69%",
      labelLeft: "64%",
      markerTop: "70%",
      markerLeft: "54%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "highlight",
        "el-morocco-club": "hide",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Club 81",
      type: "location",
      markerType: "dot",
      reference: "club-81",
      label: "Club 81",
      color: "red",
      labelTop: "71%",
      labelLeft: "50%",
      markerTop: "72%",
      markerLeft: "54.25%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "highlight",
        "club-81": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show The Windmill",
      type: "location",
      markerType: "dot",
      reference: "the-windmill",
      label: "The Windmill",
      color: "red",
      labelTop: "78.5%",
      labelLeft: "68%",
      markerTop: "79.5%",
      markerLeft: "59.5%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "highlight",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Cinderella Club",
      type: "location",
      markerType: "dot",
      reference: "cinderella-club",
      label: "Cinderella Club",
      color: "red",
      labelTop: "78.5%",
      labelLeft: "50%",
      markerTop: "79%",
      markerLeft: "58.5%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "show",
        "the-windmill": "highlight",
        "cinderella-club": "hide",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Hollywood",
      type: "location",
      markerType: "dot",
      reference: "hollywood",
      label: "Hollywood",
      color: "red",
      labelTop: "75.5%",
      labelLeft: "50%",
      markerTop: "77%",
      markerLeft: "56.5%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "show",
        "the-windmill": "show",
        "cinderella-club": "highlight",
        "hollywood": "hide",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show The Village",
      type: "location",
      markerType: "dot",
      reference: "the-village",
      label: "The Village",
      color: "red",
      labelTop: "68%",
      labelLeft: "47%",
      markerTop: "69%",
      markerLeft: "53.5%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "hollywood": "highlight",
        "the-village": "hide",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Dessau Hall",
      type: "location",
      markerType: "dot",
      reference: "dessau-hall",
      label: "Dessau Hall<br />(Established 1880s)",
      color: "red",
      labelTop: "22%",
      labelLeft: "57%",
      markerTop: "24%",
      markerLeft: "45.5%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "hollywood": "show",
        "the-village": "highlight",
        "dessau-hall": "hide",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Elm Grove Lodge",
      type: "location",
      markerType: "dot",
      reference: "elm-grove-lodge",
      label: "Elm Grove Lodge",
      color: "red",
      labelTop: "61.5%",
      labelLeft: "31%",
      markerTop: "63.5%",
      markerLeft: "40%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "hollywood": "show",
        "the-village": "show",
        "dessau-hall": "highlight",
        "elm-grove-lodge": "hide",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Sunset Tavern",
      type: "location",
      markerType: "dot",
      reference: "sunset-tavern",
      label: "Sunset Tavern",
      color: "red",
      labelTop: "31.5%",
      labelLeft: "9%",
      markerTop: "34%",
      markerLeft: "11%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "hollywood": "show",
        "the-village": "show",
        "dessau-hall": "show",
        "elm-grove-lodge": "highlight",
        "sunset-tavern": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Hilltop Inn",
      type: "location",
      markerType: "dot",
      reference: "hilltop-inn",
      label: "Hilltop Inn",
      color: "red",
      labelTop: "33.5%",
      labelLeft: "28%",
      markerTop: "36%",
      markerLeft: "21%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "hollywood": "show",
        "the-village": "show",
        "dessau-hall": "show",
        "elm-grove-lodge": "show",
        "sunset-tavern": "highlight",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Copenhagen Inn",
      type: "location",
      markerType: "dot",
      reference: "copenhagen-inn",
      label: "Copenhagen Inn",
      color: "red",
      labelTop: "36%",
      labelLeft: "11%",
      markerTop: "35.5%",
      markerLeft: "20%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "hollywood": "show",
        "the-village": "show",
        "dessau-hall": "show",
        "elm-grove-lodge": "show",
        "sunset-tavern": "show",
        "hilltop-inn": "highlight",
        "copenhagen-inn": "hide",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Riverside Inn",
      type: "location",
      markerType: "dot",
      reference: "riverside-inn",
      label: "Riverside Inn",
      color: "red",
      labelTop: "56.5%",
      labelLeft: "75%",
      markerTop: "57.5%",
      markerLeft: "66.5%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "hollywood": "show",
        "the-village": "show",
        "dessau-hall": "show",
        "elm-grove-lodge": "show",
        "sunset-tavern": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "highlight",
        "riverside-inn": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Moosehead Tavern",
      type: "location",
      markerType: "dot",
      reference: "moosehead-tavern",
      label: "Moosehead Tavern",
      color: "red",
      labelTop: "80%",
      labelLeft: "16%",
      markerTop: "81%",
      markerLeft: "26%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "hollywood": "show",
        "the-village": "show",
        "dessau-hall": "show",
        "elm-grove-lodge": "show",
        "sunset-tavern": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "show",
        "riverside-inn": "highlight",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Blue Goose",
      type: "location",
      markerType: "dot",
      reference: "blue-goose",
      label: "Blue Goose",
      color: "red",
      labelTop: "95%",
      labelLeft: "16%",
      markerTop: "97%",
      markerLeft: "8%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "hollywood": "show",
        "the-village": "show",
        "dessau-hall": "show",
        "elm-grove-lodge": "show",
        "sunset-tavern": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "show",
        "riverside-inn": "show",
        "moosehead-tavern": "highlight",
        "blue-goose": "hide",
      },
      duration: "short",
    },
    {
      step: "Blue Goose Visible",
      type: "empty",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "hollywood": "show",
        "the-village": "show",
        "dessau-hall": "show",
        "elm-grove-lodge": "show",
        "sunset-tavern": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "show",
        "riverside-inn": "show",
        "moosehead-tavern": "show",
        "blue-goose": "visible",
      },
      duration: "short",
    },
    {
      step: "Last Stage",
      type: "empty",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "the-barn": "show",
        "the-owl-club": "show",
        "the-star-club": "show",
        "skyline-club": "show",
        "the-plantation": "show",
        "prices-hall": "show",
        "threadgills": "show",
        "top-hat": "show",
        "el-morocco-club": "show",
        "club-81": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "hollywood": "show",
        "the-village": "show",
        "dessau-hall": "show",
        "elm-grove-lodge": "show",
        "sunset-tavern": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "show",
        "riverside-inn": "show",
        "moosehead-tavern": "show",
        "blue-goose": "show",
      },
      duration: "long",
    },
  ],
  "turnover": [
    {
      step: "Scroll First Text",
      type: "text",
      content: "The economic viability of these places was far from secure after the boom period of World War Two. Many venues changed hands over and over between 1945 and 1960, even if they offered the same kind of music and entertainment.",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "long",
    },
    {
      step: "Show The Windmill",
      type: "location",
      markerType: "dot",
      reference: "the-windmill",
      label: "The Windmill<br />(1945-1949)",
      color: "red",
      labelTop: "78.5%",
      labelLeft: "68%",
      markerTop: "79.5%",
      markerLeft: "59.5%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Oh Johnnie Club",
      type: "location",
      markerType: "dot",
      reference: "oh-johnnie-club",
      label: "Oh Johnnie Club<br />(1945)",
      color: "red",
      labelTop: "30.5%",
      labelLeft: "43%",
      markerTop: "33%",
      markerLeft: "37%",
      visibility: {
        "the-windmill": "highlight",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Oh Johnnie Club Visible",
      type: "empty",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "highlight",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Finish 1945",
      type: "empty",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "show",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show The Owl Club",
      type: "location",
      markerType: "dot",
      reference: "the-owl-club",
      label: "The Owl Club<br />(1946-1947)",
      color: "red",
      labelTop: "30.5%",
      labelLeft: "43%",
      markerTop: "33%",
      markerLeft: "37%",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Trocadero Dinner Club",
      type: "location",
      markerType: "dot",
      reference: "trocadero",
      label: "Trocadero Dinner Club<br />(1946-1947)",
      color: "red",
      labelTop: "69%",
      labelLeft: "64%",
      markerTop: "70%",
      markerLeft: "54%",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "highlight",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Cinderella Club",
      type: "location",
      markerType: "dot",
      reference: "cinderella",
      label: "Cinderella Club<br />(1946-1954)",
      color: "red",
      labelTop: "78.5%",
      labelLeft: "50%",
      markerTop: "79%",
      markerLeft: "58.5%",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "show",
        "trocadero": "highlight",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show El Morocco",
      type: "location",
      markerType: "dot",
      reference: "el-morocco",
      label: "El Morocco Club<br />(1947)",
      color: "red",
      labelTop: "69%",
      labelLeft: "64%",
      markerTop: "70%",
      markerLeft: "54%",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "show",
        "trocadero": "show",
        "cinderella": "highlight",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "El Morocco Club Visible",
      type: "empty",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "show",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "highlight",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "El Morocco Club closes",
      type: "empty",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "show",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "show",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Ranch Club",
      type: "location",
      markerType: "dot",
      reference: "ranch-club",
      label: "Ranch Club<br />(1947-1948)",
      color: "red",
      labelTop: "69%",
      labelLeft: "64%",
      markerTop: "70%",
      markerLeft: "54%",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "show",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Ranch Club Visible",
      type: "empty",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "show",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "highlight",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Finish 1947",
      type: "empty",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "show",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "show",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Twin Oaks",
      type: "location",
      markerType: "dot",
      reference: "twin-oaks",
      label: "Twin Oaks<br />(1948)",
      color: "red",
      labelTop: "69%",
      labelLeft: "64%",
      markerTop: "70%",
      markerLeft: "54%",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Twin Oaks",
      type: "empty",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "highlight",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Twin Oaks closes",
      type: "empty",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "show",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Lytton’s Drive Inn",
      type: "location",
      markerType: "dot",
      reference: "lyttons",
      label: "Lytton’s Drive Inn No. 2<br />(1948-1949)",
      color: "red",
      labelTop: "69%",
      labelLeft: "64%",
      markerTop: "70%",
      markerLeft: "54%",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Lenzo’s Casino",
      type: "location",
      markerType: "dot",
      reference: "lenzos",
      label: "Lenzo’s Casino<br />(1948)",
      color: "red",
      labelTop: "30.5%",
      labelLeft: "43%",
      markerTop: "33%",
      markerLeft: "37%",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "highlight",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Lenzo’s Casino visible",
      type: "empty",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "show",
        "lenzos": "highlight",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Lenzo’s Casino closes",
      type: "empty",
      visibility: {
        "the-windmill": "show",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "show",
        "lenzos": "show",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Blackie’s",
      type: "location",
      markerType: "dot",
      reference: "blackies",
      label: "Blackie’s Drive-Inn<br />(1949)",
      color: "red",
      labelTop: "78.5%",
      labelLeft: "68%",
      markerTop: "79.5%",
      markerLeft: "59.5%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "show",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Moosehead Tavern",
      type: "location",
      markerType: "dot",
      reference: "moosehead-tavern",
      label: "Moosehead Tavern<br />(1949-1953)",
      color: "red",
      labelTop: "80%",
      labelLeft: "16%",
      markerTop: "81%",
      markerLeft: "26%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "show",
        "lenzos": "hide",
        "blackies": "highlight",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Rudy’s Drive-Inn",
      type: "location",
      markerType: "dot",
      reference: "rudys-drive-inn",
      label: "Rudy’s Drive-Inn<br />(1949-1951)",
      color: "red",
      labelTop: "78.5%",
      labelLeft: "68%",
      markerTop: "79.5%",
      markerLeft: "59.5%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "highlight",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short"
    },
    {
      step: "Rudy's Drive-Inn Visible",
      type: "empty",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "show",
        "rudys-drive-inn": "highlight",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short"
    },
    {
      step: "Venues close in 1950",
      type: "empty",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "show",
        "rudys-drive-inn": "show",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short"
    },
    {
      step: "Show Southern Club",
      type: "location",
      markerType: "dot",
      reference: "southern-club",
      label: "Southern Club<br />(1951-1956)",
      color: "red",
      labelTop: "78.5%",
      labelLeft: "68%",
      markerTop: "79.5%",
      markerLeft: "59.5%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "show",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "show",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short"
    },
    {
      step: "Show Grouchy’s",
      type: "location",
      markerType: "dot",
      reference: "grouchys",
      label: "Grouchy’s<br />(1954)",
      color: "red",
      labelTop: "80%",
      labelLeft: "16%",
      markerTop: "81%",
      markerLeft: "26%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "highlight",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Grouchy’s visible",
      type: "empty",
      reference: "grouchys",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "show",
        "grouchys": "highlight",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show HoeDown Club",
      type: "location",
      markerType: "dot",
      reference: "hoedown-club",
      label: "HoeDown Club<br />(1954-1955)",
      color: "red",
      labelTop: "78.5%",
      labelLeft: "50%",
      markerTop: "79%",
      markerLeft: "58.5%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "show",
        "grouchys": "show",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Hudson’s Place",
      type: "location",
      markerType: "dot",
      reference: "hudson-place",
      label: "Hudson’s Place<br />(1954-1955)",
      color: "red",
      labelTop: "80%",
      labelLeft: "16%",
      markerTop: "81%",
      markerLeft: "26%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "show",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "highlight",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Hudson’s Place visible",
      type: "empty",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "show",
        "grouchys": "hide",
        "hudson-place": "highlight",
        "hoedown-club": "show",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Finish 1954",
      type: "empty",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "show",
        "grouchys": "hide",
        "hudson-place": "show",
        "hoedown-club": "show",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Moosehead Tavern",
      type: "location",
      markerType: "dot",
      reference: "moosehead-tavern-2",
      label: "Moosehead Tavern<br />(1955-1958)",
      color: "red",
      labelTop: "80%",
      labelLeft: "16%",
      markerTop: "81%",
      markerLeft: "26%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "show",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Club 81",
      type: "location",
      markerType: "dot",
      reference: "club-81",
      label: "Club 81<br />(1955-1973)",
      color: "red",
      labelTop: "78.5%",
      labelLeft: "50%",
      markerTop: "79%",
      markerLeft: "58.5%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "show",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "highlight",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Gil’s Club",
      type: "location",
      markerType: "dot",
      reference: "gils-club",
      label: "Gil’s Club<br />(1956-1972)",
      color: "red",
      labelTop: "78.5%",
      labelLeft: "68%",
      markerTop: "79.5%",
      markerLeft: "59.5%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "show",
        "club-81": "highlight",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Finish Moosehead Tavern",
      type: "empty",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "show",
        "club-81": "show",
        "gils-club": "highlight",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Western Inn",
      type: "location",
      markerType: "dot",
      reference: "western-inn",
      label: "The Western Inn<br />(1958-1960)",
      color: "red",
      labelTop: "80%",
      labelLeft: "16%",
      markerTop: "81%",
      markerLeft: "26%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "show",
        "gils-club": "show",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Western Inn visible",
      type: "empty",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "show",
        "gils-club": "show",
        "western-inn": "highlight",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Finish Western Inn",
      type: "empty",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "show",
        "gils-club": "show",
        "western-inn": "show",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Clear stage",
      type: "empty",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Scroll Text",
      type: "text",
      content: "The East Side of segregated Austin had similar types of small venues for dancing. The most celebrated was the Victory Grill on E. 11th Street, a hub for black audiences, jazz, and early Rhythm and Blues.",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "long",
    },
    {
      step: "Show Victory Grill",
      type: "location",
      markerType: "dot",
      reference: "victory-grill",
      label: "Victory Grill",
      color: "blue",
      labelTop: "55.5%",
      labelLeft: "47%",
      markerTop: "57%",
      markerLeft: "53.5%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "hide",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Scroll Text",
      type: "text",
      content: "The Victory Grill, begun after V-J day in 1945, started as a cafe, which also hosted a house Rhythm and Blues band with T.D. Bell. In the early 1950s, Johnny Holmes, its original owner, built an enclosed multi-leveled music hall with a raised bandstand and dance floor behind the kitchen and dining room. This venue became Austin's prime “chitlin’ circuit” stop for R&B and Soul groups in the 1950s and early 1960s.",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "highlight",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "long",
    },
    {
      step: "Scroll Text",
      type: "text",
      content: "Other small bars and venues for both jazz, big band, and Rhythm and Blues appeared on the main black and Mexican American business and entertainment thoroughfares in East Austin: E. 11th Street, E. 12th Street, E. 7th Steet, and Webberville Road.",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "show",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "long",
    },
    {
      step: "Show Hi-De-Ho",
      type: "location",
      markerType: "dot",
      reference: "hi-de-ho",
      label: "Hi-De-Ho",
      color: "blue",
      labelTop: "52.5%",
      labelLeft: "49%",
      markerTop: "54.25%",
      markerLeft: "54.5%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "show",
        "hi-de-ho": "hide",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Dew-Drop Inn",
      type: "location",
      markerType: "dot",
      reference: "dew-drop-inn",
      label: "Dew-Drop Inn",
      color: "blue",
      labelTop: "59%",
      labelLeft: "48%",
      markerTop: "57.75%",
      markerLeft: "54.5%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "show",
        "hi-de-ho": "highlight",
        "dew-drop-inn": "hide",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Big Bell Night Club",
      type: "location",
      markerType: "dot",
      reference: "big-bell-night-club",
      label: "Big Bell Night Club",
      color: "blue",
      labelTop: "53.25%",
      labelLeft: "66%",
      markerTop: "54.75%",
      markerLeft: "55%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "show",
        "hi-de-ho": "show",
        "dew-drop-inn": "highlight",
        "big-bell-night-club": "hide",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show El Internacional",
      type: "location",
      markerType: "dot",
      reference: "el-internacional",
      label: "El Internacional",
      color: "blue",
      labelTop: "55.75%",
      labelLeft: "67%",
      markerTop: "56.75%",
      markerLeft: "57.25%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "show",
        "hi-de-ho": "show",
        "dew-drop-inn": "show",
        "big-bell-night-club": "highlight",
        "el-internacional": "hide",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Show La Calle 7",
      type: "location",
      markerType: "dot",
      reference: "la-calle-7",
      label: "La Calle 7",
      color: "blue",
      labelTop: "58%",
      labelLeft: "64%",
      markerTop: "57.25%",
      markerLeft: "57.25%",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "show",
        "hi-de-ho": "show",
        "dew-drop-inn": "show",
        "big-bell-night-club": "show",
        "el-internacional": "highlight",
        "la-calle-7": "hide",
      },
      duration: "short",
    },
    {
      step: "Scroll Text",
      type: "text",
      content: "Since sources are scarce, it isn't clear if all of these bars and night clubs featured music, however.",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "show",
        "hi-de-ho": "show",
        "dew-drop-inn": "show",
        "big-bell-night-club": "show",
        "el-internacional": "show",
        "la-calle-7": "highlight",
      },
      duration: "long",
    },
    {
      step: "Finish",
      type: "empty",
      visibility: {
        "the-windmill": "hide",
        "oh-johnnie-club": "hide",
        "the-owl-club": "hide",
        "trocadero": "hide",
        "cinderella": "hide",
        "el-morocco": "hide",
        "ranch-club": "hide",
        "twin-oaks": "hide",
        "lyttons": "hide",
        "lenzos": "hide",
        "blackies": "hide",
        "moosehead-tavern": "hide",
        "rudys-drive-inn": "hide",
        "southern-club": "hide",
        "grouchys": "hide",
        "hudson-place": "hide",
        "hoedown-club": "hide",
        "moosehead-tavern-2": "hide",
        "club-81": "hide",
        "gils-club": "hide",
        "western-inn": "hide",
        "victory-grill": "show",
        "hi-de-ho": "show",
        "dew-drop-inn": "show",
        "big-bell-night-club": "show",
        "el-internacional": "show",
        "la-calle-7": "show",
      },
      duration: "long",
    }
  ],
  "segregation": [
    {
      step: "Scroll Text",
      type: "text",
      content: "Audiences in these venues did not mix, however. If anything, they were more divided in the 1940s and early 1950s. Ultimately, these new club archipelagos created a more elaborate map of music segregation in Austin.",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "long",
    },
    {
      step: "Scroll Text",
      type: "text",
      content: "Wealthy white audiences and college students still went to ballrooms and night clubs that were mostly within the city limits.",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "long",
    },
    {
      step: "Show Gregory Gym",
      type: "location",
      markerType: "dot",
      reference: "gregory-gym",
      label: "Gregory Gym",
      labelTop: "53%",
      labelLeft: "53%",
      markerTop: "56%",
      markerLeft: "48.25%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Texas Union",
      type: "location",
      markerType: "dot",
      reference: "texas-union",
      label: "Texas Union",
      labelTop: "57%",
      labelLeft: "42%",
      markerTop: "56%",
      markerLeft: "47.25%",
      visibility: {
        "gregory-gym": "highlight",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Texas Federation of Women’s Clubs",
      type: "location",
      markerType: "dot",
      reference: "womens-clubs",
      label: "Texas Federation of<br />Women’s Clubs",
      labelTop: "52.5%",
      labelLeft: "34%",
      markerTop: "55.5%",
      markerLeft: "44.75%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "highlight",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Stephen F. Austin Hotel",
      type: "location",
      markerType: "dot",
      reference: "sfa-hotel",
      label: "Stephen F. Austin Hotel",
      labelTop: "59.25%",
      labelLeft: "38%",
      markerTop: "59.25%",
      markerLeft: "49.75%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "womens-clubs": "highlight",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Driskill Hotel",
      type: "location",
      markerType: "dot",
      reference: "driskill",
      label: "Driskill Hotel",
      labelTop: "58.75%",
      labelLeft: "58%",
      markerTop: "59.75%",
      markerLeft: "50%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "womens-clubs": "show",
        "sfa-hotel": "highlight",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show The Tower",
      type: "location",
      markerType: "dot",
      reference: "the-tower",
      label: "The Tower",
      labelTop: "61%",
      labelLeft: "59%",
      markerTop: "62%",
      markerLeft: "51%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "womens-clubs": "show",
        "sfa-hotel": "show",
        "driskill": "highlight",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show La Conga",
      type: "location",
      markerType: "dot",
      reference: "la-conga",
      label: "La Conga",
      labelTop: "64.25%",
      labelLeft: "60%",
      markerTop: "65.25%",
      markerLeft: "53.25%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "womens-clubs": "show",
        "sfa-hotel": "show",
        "driskill": "show",
        "the-tower": "highlight",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Avalon",
      type: "location",
      markerType: "dot",
      reference: "avalon",
      label: "Avalon Club",
      labelTop: "45.25%",
      labelLeft: "49%",
      markerTop: "46.25%",
      markerLeft: "41.25%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "womens-clubs": "show",
        "sfa-hotel": "show",
        "driskill": "show",
        "the-tower": "show",
        "la-conga": "higlight",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Scroll Text",
      type: "text",
      content: "A few of the new postwar venues were on the periphery, but they continued the “dine and dance” model of the 1930s that appealed to this more elite and discerning crowd.",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "womens-clubs": "show",
        "sfa-hotel": "show",
        "driskill": "show",
        "the-tower": "show",
        "la-conga": "show",
        "avalon": "highlight",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "long",
    },
    {
      step: "Show Club 81",
      type: "location",
      markerType: "dot",
      reference: "club-81",
      label: "Club 81",
      labelTop: "71%",
      labelLeft: "50%",
      markerTop: "72%",
      markerLeft: "54.25%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "womens-clubs": "show",
        "sfa-hotel": "show",
        "driskill": "show",
        "the-tower": "show",
        "la-conga": "show",
        "avalon": "show",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show El Morocco Club",
      type: "location",
      markerType: "dot",
      reference: "el-morocco",
      label: "El Morocco Club",
      labelTop: "69%",
      labelLeft: "64%",
      markerTop: "70%",
      markerLeft: "54%",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "womens-clubs": "show",
        "sfa-hotel": "show",
        "driskill": "show",
        "the-tower": "show",
        "la-conga": "show",
        "avalon": "show",
        "club-81": "highlight",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Scroll Text",
      type: "text",
      content: "Working class white audiences saw music outside of Austin city limits. These Honky Tonks were almost all North, South, and West of the city limits.",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "womens-clubs": "show",
        "sfa-hotel": "show",
        "driskill": "show",
        "the-tower": "show",
        "la-conga": "show",
        "avalon": "show",
        "club-81": "show",
        "el-morocco": "highlight",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "long",
    },
    {
      step: "Hold first venue group",
      type: "empty",
      visibility: {
        "gregory-gym": "show",
        "texas-union": "show",
        "womens-clubs": "show",
        "sfa-hotel": "show",
        "driskill": "show",
        "the-tower": "show",
        "la-conga": "show",
        "avalon": "show",
        "club-81": "show",
        "el-morocco": "show",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "long",
    },
    {
      step: "Clear first venue group",
      type: "empty",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "long"
    },
    {
      step: "Show The Village",
      type: "location",
      markerType: "dot",
      reference: "the-village",
      label: "The Village",
      color: "red",
      labelTop: "68%",
      labelLeft: "47%",
      markerTop: "69%",
      markerLeft: "53.5%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Mrs. Elmer’s Ranch House",
      type: "location",
      markerType: "dot",
      reference: "mrs-elmers-ranch-house",
      label: "Mrs. Elmer’s Ranch House",
      color: "red",
      labelTop: "71.5%",
      labelLeft: "69%",
      markerTop: "72.5%",
      markerLeft: "54.5%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "highlight",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Elm Grove Lodge",
      type: "location",
      markerType: "dot",
      reference: "elm-grove-lodge",
      label: "Elm Grove Lodge",
      color: "red",
      labelTop: "61.5%",
      labelLeft: "31%",
      markerTop: "63.5%",
      markerLeft: "40%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "highlight",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Cedar Crest Lodge",
      type: "location",
      markerType: "dot",
      reference: "cedar-crest-lodge",
      label: "Cedar Crest Lodge",
      color: "red",
      labelTop: "63.75%",
      labelLeft: "22%",
      markerTop: "64.75%",
      markerLeft: "31.75%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "highlight",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Threadgill’s",
      type: "location",
      markerType: "dot",
      reference: "threadgills",
      label: "Threadgill’s",
      color: "red",
      labelTop: "45%",
      labelLeft: "34%",
      markerTop: "46%",
      markerLeft: "40.5%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "highlight",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Skyline Club",
      type: "location",
      markerType: "dot",
      reference: "skyline",
      label: "Skyline Club",
      color: "red",
      labelTop: "38%",
      labelLeft: "35%",
      markerTop: "37%",
      markerLeft: "39%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "highlight",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Riverside Inn",
      type: "location",
      markerType: "dot",
      reference: "riverside-inn",
      label: "Riverside Inn",
      color: "red",
      labelTop: "56.5%",
      labelLeft: "75%",
      markerTop: "57.5%",
      markerLeft: "66.5%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "highlight",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Top Hat",
      type: "location",
      markerType: "dot",
      reference: "top-hat",
      label: "Top Hat",
      color: "red",
      labelTop: "73.25%",
      labelLeft: "50%",
      markerTop: "73.5%",
      markerLeft: "54.5%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "highlight",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Hollywood",
      type: "location",
      markerType: "dot",
      reference: "hollywood",
      label: "Hollywood",
      color: "red",
      labelTop: "75.5%",
      labelLeft: "50%",
      markerTop: "77%",
      markerLeft: "56.5%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "highlight",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show The Windmill",
      type: "location",
      markerType: "dot",
      reference: "the-windmill",
      label: "The Windmill",
      color: "red",
      labelTop: "78.5%",
      labelLeft: "68%",
      markerTop: "79.5%",
      markerLeft: "59.5%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "highlight",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Cinderella Club",
      type: "location",
      markerType: "dot",
      reference: "cinderella-club",
      label: "Cinderella Club",
      color: "red",
      labelTop: "78.5%",
      labelLeft: "50%",
      markerTop: "79%",
      markerLeft: "58.5%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "highlight",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Moosehead Tavern",
      type: "location",
      markerType: "dot",
      reference: "moosehead-tavern",
      label: "Moosehead Tavern",
      color: "red",
      labelTop: "80%",
      labelLeft: "16%",
      markerTop: "81%",
      markerLeft: "26%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "highlight",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Blue Goose",
      type: "location",
      markerType: "dot",
      reference: "blue-goose",
      label: "Blue Goose",
      color: "red",
      labelTop: "95%",
      labelLeft: "16%",
      markerTop: "97%",
      markerLeft: "8%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "moosehead-tavern": "highlight",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Hilltop Inn",
      type: "location",
      markerType: "dot",
      reference: "hilltop-inn",
      label: "Hilltop Inn",
      color: "red",
      labelTop: "33.5%",
      labelLeft: "28%",
      markerTop: "36%",
      markerLeft: "21%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "moosehead-tavern": "show",
        "blue-goose": "highlight",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Copenhagen Inn",
      type: "location",
      markerType: "dot",
      reference: "copenhagen-inn",
      label: "Copenhagen Inn",
      color: "red",
      labelTop: "36%",
      labelLeft: "11%",
      markerTop: "35.5%",
      markerLeft: "20%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "moosehead-tavern": "show",
        "blue-goose": "show",
        "hilltop-inn": "highlight",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Rocky Ridge Tavern",
      type: "location",
      markerType: "arrow",
      arrowDirection: "turn-nw",
      reference: "rocky-ridge-tavern",
      label: "To Rocky Ridge Tavern",
      color: "red",
      labelTop: "6%",
      labelLeft: "17%",
      markerTop: "4%",
      markerLeft: "22%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "moosehead-tavern": "show",
        "blue-goose": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "highlight",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Sunset Tavern",
      type: "location",
      markerType: "dot",
      reference: "sunset-tavern",
      label: "Sunset Tavern",
      color: "red",
      labelTop: "31.5%",
      labelLeft: "9%",
      markerTop: "34%",
      markerLeft: "11%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "moosehead-tavern": "show",
        "blue-goose": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "show",
        "rocky-ridge-tavern": "highlight",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show The Plantation",
      type: "location",
      markerType: "dot",
      reference: "the-plantation",
      label: "The Plantation",
      color: "red",
      labelTop: "34%",
      labelLeft: "48%",
      markerTop: "35%",
      markerLeft: "39.5%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "moosehead-tavern": "show",
        "blue-goose": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "show",
        "rocky-ridge-tavern": "show",
        "sunset-tavern": "highlight",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show The Owl Club",
      type: "location",
      markerType: "dot",
      reference: "owl-club",
      label: "The Owl Club",
      color: "red",
      labelTop: "30.5%",
      labelLeft: "43%",
      markerTop: "33%",
      markerLeft: "37%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "moosehead-tavern": "show",
        "blue-goose": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "show",
        "rocky-ridge-tavern": "show",
        "sunset-tavern": "show",
        "the-plantation": "highlight",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Dessau Hall",
      type: "location",
      markerType: "dot",
      reference: "dessau-hall",
      label: "Dessau Hall",
      color: "red",
      labelTop: "22%",
      labelLeft: "57%",
      markerTop: "24%",
      markerLeft: "45.5%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "moosehead-tavern": "show",
        "blue-goose": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "show",
        "rocky-ridge-tavern": "show",
        "sunset-tavern": "show",
        "the-plantation": "show",
        "owl-club": "highlight",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Price’s Hall",
      type: "location",
      markerType: "dot",
      reference: "prices-hall",
      label: "Price’s Hall",
      color: "red",
      labelTop: "12%",
      labelLeft: "24.5%",
      markerTop: "13%",
      markerLeft: "17%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "moosehead-tavern": "show",
        "blue-goose": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "show",
        "rocky-ridge-tavern": "show",
        "sunset-tavern": "show",
        "the-plantation": "show",
        "owl-club": "show",
        "dessau-hall": "highlight",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show The Barn",
      type: "location",
      markerType: "dot",
      reference: "the-barn",
      label: "The Barn",
      color: "red",
      labelTop: "9%",
      labelLeft: "21.5%",
      markerTop: "10%",
      markerLeft: "15%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "moosehead-tavern": "show",
        "blue-goose": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "show",
        "rocky-ridge-tavern": "show",
        "sunset-tavern": "show",
        "the-plantation": "show",
        "owl-club": "show",
        "dessau-hall": "show",
        "prices-hall": "highlight",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Star Club",
      type: "location",
      markerType: "arrow",
      arrowDirection: "turn-nw",
      reference: "star-club",
      label: "To the Star Club",
      color: "red",
      labelTop: "5%",
      labelLeft: "20.5%",
      markerTop: "6%",
      markerLeft: "10%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "moosehead-tavern": "show",
        "blue-goose": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "show",
        "rocky-ridge-tavern": "show",
        "sunset-tavern": "show",
        "the-plantation": "show",
        "owl-club": "show",
        "dessau-hall": "show",
        "prices-hall": "show",
        "the-barn": "highlight",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Hold second group of venues",
      type: "empty",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "moosehead-tavern": "show",
        "blue-goose": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "show",
        "rocky-ridge-tavern": "show",
        "sunset-tavern": "show",
        "the-plantation": "show",
        "owl-club": "show",
        "dessau-hall": "show",
        "prices-hall": "show",
        "the-barn": "show",
        "star-club": "highlight",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short"
    },
    {
      step: "Hold second group of venues",
      type: "empty",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "show",
        "mrs-elmers-ranch-house": "show",
        "elm-grove-lodge": "show",
        "cedar-crest-lodge": "show",
        "threadgills": "show",
        "skyline": "show",
        "riverside-inn": "show",
        "top-hat": "show",
        "hollywood": "show",
        "the-windmill": "show",
        "cinderella-club": "show",
        "moosehead-tavern": "show",
        "blue-goose": "show",
        "hilltop-inn": "show",
        "copenhagen-inn": "show",
        "rocky-ridge-tavern": "show",
        "sunset-tavern": "show",
        "the-plantation": "show",
        "owl-club": "show",
        "dessau-hall": "show",
        "prices-hall": "show",
        "the-barn": "show",
        "star-club": "show",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "long"
    },
    {
      step: "Clear second group of venues",
      type: "empty",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short"
    },
    {
      step: "Scroll Text",
      type: "text",
      content: "Black and Latino/a audiences remained in East Austin, where city planners and city administrators had forcefully pushed African and Mexican Americans since the late 1920s.",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "long"
    },
    {
      step: "Show Victory Grill",
      type: "location",
      markerType: "dot",
      reference: "victory-grill",
      label: "Victory Grill",
      color: "blue",
      labelTop: "55.5%",
      labelLeft: "47%",
      markerTop: "57%",
      markerLeft: "53.5%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "hide",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show La Calle 7",
      type: "location",
      markerType: "dot",
      reference: "la-calle-7",
      label: "La Calle 7",
      color: "blue",
      labelTop: "58%",
      labelLeft: "64%",
      markerTop: "57.25%",
      markerLeft: "57.25%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "highlight",
        "la-calle-7": "hide",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Dew-Drop Inn",
      type: "location",
      markerType: "dot",
      reference: "dew-drop-inn",
      label: "Dew-Drop Inn",
      color: "blue",
      labelTop: "59%",
      labelLeft: "48%",
      markerTop: "57.75%",
      markerLeft: "54.5%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "show",
        "la-calle-7": "highlight",
        "dew-drop-inn": "hide",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show El Internacional",
      type: "location",
      markerType: "dot",
      reference: "el-internacional",
      label: "El Internacional",
      color: "blue",
      labelTop: "55.75%",
      labelLeft: "67%",
      markerTop: "56.75%",
      markerLeft: "57.25%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "show",
        "la-calle-7": "show",
        "dew-drop-inn": "highlight",
        "el-internacional": "hide",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show Hi-De-Ho",
      type: "location",
      markerType: "dot",
      reference: "hi-de-ho",
      label: "Hi-De-Ho",
      color: "blue",
      labelTop: "52.5%",
      labelLeft: "49%",
      markerTop: "54.25%",
      markerLeft: "54.5%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "show",
        "la-calle-7": "show",
        "dew-drop-inn": "show",
        "el-internacional": "highlight",
        "hi-de-ho": "hide",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Show big Bell Night Club",
      type: "location",
      markerType: "dot",
      reference: "big-bell-night-club",
      label: "Big Bell Night Club",
      color: "blue",
      labelTop: "53.25%",
      labelLeft: "66%",
      markerTop: "54.75%",
      markerLeft: "55%",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "show",
        "la-calle-7": "show",
        "dew-drop-inn": "show",
        "el-internacional": "show",
        "hi-de-ho": "highlight",
        "big-bell-night-club": "hide",
      },
      duration: "short",
    },
    {
      step: "Big Bell Visible",
      type: "empty",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "show",
        "la-calle-7": "show",
        "dew-drop-inn": "show",
        "el-internacional": "show",
        "hi-de-ho": "show",
        "big-bell-night-club": "highlight",
      },
      duration: "short",
    },
    {
      step: "Finish",
      type: "empty",
      visibility: {
        "gregory-gym": "hide",
        "texas-union": "hide",
        "womens-clubs": "hide",
        "sfa-hotel": "hide",
        "driskill": "hide",
        "the-tower": "hide",
        "la-conga": "hide",
        "avalon": "hide",
        "club-81": "hide",
        "el-morocco": "hide",
        "the-village": "hide",
        "mrs-elmers-ranch-house": "hide",
        "elm-grove-lodge": "hide",
        "cedar-crest-lodge": "hide",
        "threadgills": "hide",
        "skyline": "hide",
        "riverside-inn": "hide",
        "top-hat": "hide",
        "hollywood": "hide",
        "the-windmill": "hide",
        "cinderella-club": "hide",
        "moosehead-tavern": "hide",
        "blue-goose": "hide",
        "hilltop-inn": "hide",
        "copenhagen-inn": "hide",
        "rocky-ridge-tavern": "hide",
        "sunset-tavern": "hide",
        "the-plantation": "hide",
        "owl-club": "hide",
        "dessau-hall": "hide",
        "prices-hall": "hide",
        "the-barn": "hide",
        "star-club": "hide",
        "victory-grill": "show",
        "la-calle-7": "show",
        "dew-drop-inn": "show",
        "el-internacional": "show",
        "hi-de-ho": "show",
        "big-bell-night-club": "show",
      },
      duration: "short",
    },
  ]
};
