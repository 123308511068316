import React from "react";

import JukeboxLink from "./jukebox-link";

export default (props) => {
  const links = props.content.videos.map((video, idx) => (
    <JukeboxLink content={video}
                 launchPopupJukebox={props.launchPopupJukebox}
                 key={idx} />
  ));

  return (
    <div className="jukebox-collection">
      {links}
    </div>
  )
}