import React from "react";
import { Link } from "gatsby";

 export default (props) => {
  if (props.citedVenues.length > 0) {
    return (
      <div className="related-views-block">
        <h2>Related Venues</h2>
        <ul className="listing-grid">
          {props.citedVenues.map((venue, idx)=> (
            <li key={idx} className="listing-grid__cell">
              <Link to={`/venues/${venue.node.reference}`}>
                {venue.node.canonical_name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  
  return null;
}
