import React from "react";

import { marked } from "marked";

export default (props) => {
  let formattedText = marked(props.content.text);
  formattedText = formattedText.replace('<p>', '');
  formattedText = formattedText.replace('</p>', '');

  return (
    <h2 dangerouslySetInnerHTML={{ __html: formattedText }} />
  );
}
