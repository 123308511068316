import React from "react";

export default (props) => {
  console.log(props);
  const caption = (props.content.caption) ?
                  (<p className="caption">{props.content.caption}</p>) :
                  null;

  return (
    <div className="video-block">
      <div className="video-container">
        <iframe title={props.content.videoId} src={`https://www.youtube.com/embed/${props.content.videoId}?modestbranding=1&showinfo=0&rel=0`}
                frameborder="0"
                allowfullscreen />
      </div>
      {caption}
    </div>
  );
}
