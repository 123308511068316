import React from "react";

class CloseButton extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  buildClassName() {
    return `close-button ${(this.props.playerHovered) ? 'hovered' : ''}`
  }

  onClick(e) {
    this.props.narrative.setState({ jukeboxVideo: null })
  }

  render() {
    return (
      <button className={this.buildClassName()} 
              onClick={this.onClick}>Close</button>
    );
  }
}

export default CloseButton;