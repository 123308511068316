import React from "react";
import { v4 as uuidv4 } from "uuid";

class AudioPlayerList extends React.Component {
  constructor(props) {
    super(props);

    this.handlePlayPauseClick = this.handlePlayPauseClick.bind(this);
    this.handleAudioTimeUpdate = this.handleAudioTimeUpdate.bind(this);
    this.handleAudioCanPlayThrough = this.handleAudioCanPlayThrough.bind(this);

    const players = this.props.content.tracks.map((track, idx) => {
      return {
        id: idx,
        title: track.title,
        slug: track.slug,
        path: track.path,
        format: track.format,
        progress: 0,
        duration: null,
        playing: false,
        audioTrackRef: React.createRef(),
      };
    });

    this.state = {
      id: uuidv4(),
      players: players
    }
  }

  handleAudioCanPlayThrough(player) {
    const newDuration = player.audioTrackRef.current.duration;

    const nextPlayers = this.state.players.map((el) => {
      if (el.id === player.id) {
        return Object.assign({}, el, { duration: newDuration });
      } else {
        return el;
      }
    });
    this.setState({ players: nextPlayers });
  }

  handleAudioTimeUpdate(player) {
    const newProgress = player.audioTrackRef.current.currentTime;

    const nextPlayers = this.state.players.map((el) => {
      if (el.id === player.id) {
        if (player.playing && player.duration) {
          const newPlaying = (player.progress < player.duration);

          return Object.assign({}, el, { progress: newProgress, playing: newPlaying });
        } else {
          return Object.assign({}, el, { progress: newProgress });
        }
      } else {
        return el;
      }
    });
    this.setState({ players: nextPlayers });
  }

  handlePlayPauseClick(player) {
    const audioTrack = player.audioTrackRef.current;
    let newPlaying;

    this.state.players.forEach((el) => {
      const track = el.audioTrackRef.current;

      if (el.id !== player.id) {
        track.pause();
      }
    });
    
    if (audioTrack.paused) {
      newPlaying = true;
      audioTrack.play();
    } else {
      newPlaying = false;
      audioTrack.pause();
    }

    const nextPlayers = this.state.players.map((el) => {
      if (el.id === player.id) {
        return Object.assign({}, el, { playing: newPlaying });
      } else {
        return Object.assign({}, el, { playing: false });
      }
    });
    this.setState({ players: nextPlayers });
  }

  render() {
    const players = this.state.players.map((player, idx) => (
      <AudioPlayer 
        listId={this.state.id} 
        player={player}
        handlePlayPauseClick={this.handlePlayPauseClick}
        handleAudioTimeUpdate={this.handleAudioTimeUpdate}
        handleAudioCanPlayThrough={this.handleAudioCanPlayThrough}
        key={idx}
      />
    ));

    return (
      <div className="audio-player__list">
        {players}
      </div>
    );
  }
}

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.handlePlayPauseClick = this.handlePlayPauseClick.bind(this);
  }

  handleAudioCanPlayThrough = (e) => this.props.handleAudioCanPlayThrough(this.props.player);
  handleAudioTimeUpdate = (e) => this.props.handleAudioTimeUpdate(this.props.player);
  handlePlayPauseClick = (e) => this.props.handlePlayPauseClick(this.props.player);

  render() {
    return (
      <div id={this.props.player.slug} className="audio-player__player__wrapper">
        <div className='audio-player__player__contents'>
          <audio 
            ref={this.props.player.audioTrackRef}
            id={`audio-track-${this.props.listId}-${this.props.player.id}`}
            onCanPlayThrough={this.handleAudioCanPlayThrough}
            onTimeUpdate={this.handleAudioTimeUpdate}
          >
            <source src={this.props.player.path} type={this.props.player.format} />
          </audio>

          <AudioPlayPauseButton 
            player={this.props.player}
            handleClick={this.handlePlayPauseClick}
          />

          <div className="audio-player__details">
            <div className="audio-player__meta">
              <div className='audio-player__title'>{this.props.player.title}</div>

              <AudioTimeDisplay
                player={this.props.player}
              />
            </div>

            <AudioProgress 
              player={this.props.player}
            />
          </div>
        </div>
      </div>
    );
  }
}

class AudioTimeDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.formatTime = this.formatTime.bind(this);
  }

  formatTime() {
    const remaining = this.props.player.duration - this.props.player.progress;
    const minutes = Math.floor(remaining / 60);
    const seconds = Math.floor(remaining - (minutes * 60));

    let formattedTime = '';

    if (this.props.player.duration) {
      if (minutes === 0) {
        if (seconds > 45) {
          formattedTime = '1 minute';
        } else if (seconds > 0) {
          formattedTime = 'Less than a minute'
        } else {
          formattedTime = 'Finished';
        }
      } else if (minutes < 0) {
        formattedTime = 'Finished';
      } else {
        if (seconds > 45) {
          formattedTime = `${minutes + 1} minutes`;
        } else if (seconds > 15) {
          formattedTime = `${minutes} and a half minutes`;
        } else {
          if (minutes === 1) {
            formattedTime = '1 minute';
          } else {
            formattedTime = `${minutes} minutes`;
          }
        }
      }

      if (this.props.player.progress > 0 && remaining > 0) {
        formattedTime += ' remaining';
      }

      return formattedTime;
    } else {
      return '…';
    }
  }

  render() {
    return (
      <div className='audio-player__time'>
        {this.formatTime()}
      </div>
    )
  }
}

class AudioPlayPauseButton extends React.Component {
  handleClick = (e) => this.props.handleClick(e);

  render() {
    let pauseButtonClasses, playButtonClasses, restartButtonClasses = '';

    if (this.props.player.playing) {
      // Audio is playing
      pauseButtonClasses = 'audio-player__play-button__icon-show';
      playButtonClasses = 'audio-player__play-button__icon-hide';
      restartButtonClasses = 'audio-player__play-button__icon-hide';
    } else {
      if (this.props.player.duration) {
        if (this.props.player.progress >= this.props.player.duration) {
          // Audio is not playing, but has finished
          restartButtonClasses = 'audio-player__play-button__icon-show';
          pauseButtonClasses = 'audio-player__play-button__icon-hide';
          playButtonClasses = 'audio-player__play-button__icon-hide';
        } else {
          // Audio is not playing, and playhead is either unstarted or in intermediate state
          playButtonClasses = 'audio-player__play-button__icon-show';
          pauseButtonClasses = 'audio-player__play-button__icon-hide';
          restartButtonClasses = 'audio-player__play-button__icon-hide';
        }
      } else {
        // Audio is not loaded yet
        playButtonClasses = 'audio-player__play-button__icon-show';
        pauseButtonClasses = 'audio-player__play-button__icon-hide';
        restartButtonClasses = 'audio-player__play-button__icon-hide';
      }
    }

    return (
      <div className="audio-player__play-button">
        <button onClick={this.handleClick}>
          <span className={pauseButtonClasses}>
            <i className="fas fa-pause" />
          </span>
          <span className={playButtonClasses}>
            <i className="fas fa-play" />
          </span>
          <span className={restartButtonClasses}>
            <i className="fas fa-undo-alt" />
          </span>
        </button>
      </div>
    );
  }
}

class AudioProgress extends React.Component {
  render() {
    return (
      <div className='audio-player__player-progress__container'>
        <div 
          className='audio-player__player-progress__indicator'
          style={{ width: `${this.props.player.progress / this.props.player.duration * 100}%`}}
        ></div>
      </div>
    );
  }
}

export default AudioPlayerList;