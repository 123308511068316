import React from "react";
import { Link } from "gatsby";

export default (props) => {
  if (props.citedArtists.length > 0) {
    return (
      <div className="related-views-block">
        <h2>Related Performers</h2>
        <ul className="listing-grid">
          {props.citedArtists.map((artist, idx) => (
            <li key={idx} className="listing-grid__cell">
              <Link to={`/artists/${artist.node.reference}`}>
                {artist.node.canonical_name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
}