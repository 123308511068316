import React from "react";
import { Link } from "gatsby";

import "./national-bands-timeline.scss";

export default (props) => (
  <div className="custom-block custom-national-bands-timeline">
    <ul className="year-list">
      <li>
        <div className="contents-card">
          <h4>1931</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/louis-armstrong">Louis Armstrong</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                September 26
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1932</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/king-oliver-and-his-orchestra">King Oliver</Link>
              </span>
              <span className="venue">
                <Link to="/venues/austin-country-club">Austin Country Club</Link>
              </span>
              <span className="date">
                November 26
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1933</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/frankie-masters-and-his-orchestra">Frankie Masters</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                April 8
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/henry-busse-and-his-orchestra">Henry Busse</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>/
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                September 18-20
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/duke-ellington-and-his-orchestra">Duke Ellington</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                October 12
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1934</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/vincent-lopez-and-his-orchestra">Vincent Lopez</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                November 11
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1935</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/guy-lombardo-and-his-royal-canadians">Guy Lombardo</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>/
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                February 2
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/benny-goodman-and-his-orchestra">Benny Goodman</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>
              </span>
              <span className="date">
                November 2
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1936</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/joe-venuti-and-his-orchestra">Joe Venuti</Link>
              </span>
              <span className="venue">
                <Link to="/venues/federated-womens-club-building">Texas Federated Women’s Club</Link>
              </span>
              <span className="date">
                February 15
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/jimmie-lunceford-and-his-orchestra">Jimmie Lunceford</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                March 9
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/paul-whiteman-and-his-orchestra">Paul Whiteman</Link>
              </span>
              <span className="venue">
                <Link to="/venues/hogg-auditorium">Hogg Memorial Auditorium</Link>
              </span>
              <span className="date">
                September 9
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/duke-ellington-and-his-orchestra">Duke Ellington</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>
              </span>
              <span className="date">
                November 26
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/joe-reichman-and-his-orchestra">Joe Reichman</Link>
              </span>
              <span className="venue">
                <Link to="/venues/austin-country-club">Austin Country Club</Link>
              </span>
              <span className="date">
                December 11
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/ted-fio-rito-and-his-orchestra">Ted Fio Rito</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                December 19
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1937</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/herbie-kay-and-his-orchestra">Herbie Kay</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>/
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                February 19
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/carol-lofner-and-his-orchestra">Carol Lofner</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>
              </span>
              <span className="date">
                March 6
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/joe-venuti-and-his-orchestra">Joe Venuti</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                April 16
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/george-olson-and-his-orchestra">George Olson</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                May 15
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/jackie-coogan-and-his-orchestra">Jackie Coogan</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                September 30
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/jimmie-lunceford">Jimmie Lunceford</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                October 7
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/shep-fields-and-his-orchestra">Shep Fields</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                November 13
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/red-nichols-and-his-orchestra">Red Nichols</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                December 18
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1938</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/jimmie-lunceford-and-his-orchestra">Jimmie Lunceford</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                February 26
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/herbie-kay-and-his-orchestra">Herbie Kay</Link>
              </span>
              <span className="venue">
                <Link to="/venues/federated-womens-club-building">Texas Federated Women’s Club</Link>
              </span>
              <span className="date">
                March 11
              </span>
            </li>
            <li>
              <span className="artist">
                Glen Gray
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                March 16
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/anson-weeks-and-his-orchestra">Anson Weeks</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>
              </span>
              <span className="date">
                April 2
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/george-hamilton-and-his-music-box-music">George Hamilton</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                April 8
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/joe-reichman-and-his-orchestra">Joe Reichman</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>
              </span>
              <span className="date">
                April 30
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/eddie-fitzpatrick-and-his-orchestra">Eddie Fitzpatrick</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                September 16-17
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/joe-reichman-and-his-orchestra">Joe Reichman</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                September 24
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/gabe-martel-and-his-orchestra">Gabe Martel</Link>
              </span>
              <span className="venue">
                <Link to="/venues/federated-womens-club-building">Texas Federated Women’s Club</Link>
              </span>
              <span className="date">
                November 24
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/henry-busse-and-his-orchestra">Henry Busse</Link>
              </span>
              <span className="venue">
                <Link to="/venues/federated-womens-club-building">Texas Federated Women’s Club</Link>
              </span>
              <span className="date">
                November 25
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/george-hamilton-and-his-music-box-music">George Hamilton</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>
              </span>
              <span className="date">
                December 10
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1939</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/gabe-martel-and-his-orchestra">Gabe Martel</Link>
              </span>
              <span className="venue">
                <Link to="/venues/austin-country-club">Austin Country Club</Link>
              </span>
              <span className="date">
                January 6
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/eddie-fitzpatrick-and-his-orchestra">Eddie Fitzpatrick</Link>
              </span>
              <span className="venue">
                <Link to="/venues/federated-womens-club-building">Texas Federated Women’s Club</Link>
              </span>
              <span className="date">
                March 10
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/george-hamilton-and-his-music-box-music">George Hamilton</Link>
              </span>
              <span className="venue">
                <Link to="/venues/austin-country-club">Austin Country Club</Link>
              </span>
              <span className="date">
                April 14
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/joe-venuti-and-his-orchestra">Joe Venuti</Link>
              </span>
              <span className="venue">
                <Link to="/venues/federated-womens-club-building">Texas Federated Women’s Club</Link>
              </span>
              <span className="date">
                May 13
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/jan-garber-and-his-orchestra">Jan Garber</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>/
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                September 30
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/leonard-keller-and-his-band">Leonard Keller</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>
              </span>
              <span className="date">
                October 7
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/happy-felton-and-his-orchestra">Happy Felton</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                October 21
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/vincent-lopez-and-his-orchestra">Vincent Lopez</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>/
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                October 28
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/erskine-hawkins-and-his-orchestra">Erskine Hawkins</Link>
              </span>
              <span className="venue">
                <Link to="/venues/federated-womens-club-building">Texas Women’s Federated Club</Link>
              </span>
              <span className="date">
                November 3
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1940</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/vincent-lopez-and-his-orchestra">Vincent Lopez</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>
              </span>
              <span className="date">
                March 1
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/del-courtney-and-his-orchestra">Del Courtney</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                April 5
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/jimmie-lunceford-and-his-orchestra">Jimmie Lunceford</Link>
              </span>
              <span className="venue">
                <Link to="/venues/stephen-f-austin-hotel">Stephen F. Austin Hotel</Link>
              </span>
              <span className="date">
                April 19
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/ozzie-nelson-and-his-orchestra">Ozzie Nelson</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                September 21
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/malcolm-beelbys-royal-hawaiian-hotel-orchestra">Malcolm Beelby</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                September 28
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/hal-kemp-and-his-orchestra">Hal Kemp</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                October 18
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/al-donahue-and-his-orchestra">Al Donahue</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                November 28
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1941</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/jan-garber-and-his-orchestra">Jan Garber</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                February 14
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/bernie-cummins-and-his-orchestra">Bernie Cummins</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>
              </span>
              <span className="date">
                February 28
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/jimmie-lunceford-and-his-orchestra">Jimmie Lunceford</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>
              </span>
              <span className="date">
                March 7
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/count-basie-and-his-orchestra">Count Basie</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                March 21
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/duke-ellington-and-his-orchestra">Duke Ellington</Link>
              </span>
              <span className="venue">
                <Link to="/venues/federated-womens-club-building">Texas Federation of Women’s Club</Link>
              </span>
              <span className="date">
                April 19
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/kay-kyser-and-his-orchestra">Kay Kyser</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                April 25
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/eddie-fitzpatrick-and-his-orchestra">Eddie Fitzpatrick</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>,&nbsp;
              </span>
              <span className="date">
                May 9
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/dick-smith-and-his-orchestra">Dick Smith</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                October 3
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/red-nichols-and-his-orchestra">Red Nichols</Link>
              </span>
              <span className="venue">
                <Link to="/venues/federated-womens-club-building">Texas Federation of Women’s Clubs</Link>
              </span>
              <span className="date">
                November 22
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1942</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/jimmie-lunceford-and-his-orchestra">Jimmie Lunceford</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                February 20
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/wayne-king-and-his-orchestra">Wayne King</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>,&nbsp;
              </span>
              <span className="date">
                March 6
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/tommy-dorsey-and-his-orchestra">Tommy Dorsey</Link> with Frank Sinatra
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                March 13
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/erskine-hawkins-and-his-orchestra">Erskine Hawkins</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                September 18
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/louis-armstrong">Louis Armstrong</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                December 11
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1943</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/jack-teagarden-and-his-orchestra">Jack Teagarden</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                September 17
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/fletcher-henderson-orchestra">Fletcher Henderson</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                October 2
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/benny-carter-and-his-orchestra">Benny Carter</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                November 20
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1944</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/tommy-dorsey-and-his-orchestra">Tommy Dorsey</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                July 9
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1945</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/carol-lofner-and-his-orchestra">Carol Lofner</Link>
              </span>
              <span className="venue">
                <Link to="/venues/federated-womens-club-building">Texas Federation of Women’s Clubs</Link>
              </span>
              <span className="date">
                January 13
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/billy-eckstine-orchestra">Billy Eckstine</Link>
              </span>
              <span className="venue">
                <Link to="/venues/texas-union">Texas Union</Link>
              </span>
              <span className="date">
                January 27
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/stan-kenton-and-his-orchestra">Stan Kenton</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                April 6
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/jimmie-lunceford-and-his-orchestra">Jimmie Lunceford</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>,&nbsp;
              </span>
              <span className="date">
                May 12
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/benny-carter-and-his-orchestra">Benny Carter</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                November 10
              </span>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="contents-card">
          <h4>1946</h4>
          <ul>
            <li>
              <span className="artist">
                <Link to="/artists/henry-busse-and-his-orchestra">Henry Busse</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                January 5
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/johnny-scat-davis-and-his-orchestra">Johnny “Scat” Davis</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>,&nbsp;
              </span>
              <span className="date">
                March 16
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/jimmie-lunceford-and-his-orchestra">Jimmie Lunceford</Link>
              </span>
              <span className="venue">
                <Link to="/venues/federated-womens-club-building">Texas Federation of Women’s Club</Link>
              </span>
              <span className="date">
                March 22
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/duke-ellington-and-his-orchestra">Duke Ellington</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                April 13
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/jan-garber-and-his-orchestra">Jan Garber</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                April 27
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/van-kirkpatrick-and-his-orchestra">Van Kirkpatrick</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                September 21
              </span>
            </li>
            <li>
              <span className="artist">
                Glenn Miller with Tex Beneke
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                November 22
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/frankie-masters-and-his-orchestra">Frankie Masters</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                November 27
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/xavier-cugat-and-his-orchestra">Xavier Cugat</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                December 5
              </span>
            </li>
            <li>
              <span className="artist">
                <Link to="/artists/ella-fitzgerald-dizzie-gillespie-orchestra">Dizzy Gillespie with Ella Fitzgerald</Link>
              </span>
              <span className="venue">
                <Link to="/venues/gregory-gym">Gregory Gym</Link>
              </span>
              <span className="date">
                December 14
              </span>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
)