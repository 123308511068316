import React from 'react';

import { ArrowSvg } from '../../../common/Svgs';

export default (props) => {
  const animationState = props.animationState;
  const visibilityStates = props.visibilityStates;

  const opacity = (visibilityStates[animationState.reference] === 'hide') ? 0 : 1;
  const highlight = (visibilityStates[animationState.reference] === 'highlight');

  const markerType = (animationState.markerType === 'arrow') ? 'arrow' : 'dot';

  return (
    <>
      {(markerType === 'arrow') ? (
        <div
          className={`ht-arrow-marker ${animationState.color} ${animationState.arrowDirection}`}
          style={{
            opacity: opacity,
            top: animationState.markerTop,
            left: animationState.markerLeft,
          }}
        >
          {ArrowSvg}
        </div>
      ) : (
        <div
          className={`ht-map-marker ${animationState.color}`}
          style={{
            opacity: opacity,
            top: animationState.markerTop,
            left: animationState.markerLeft,
          }}
        />
      )}
      <div
        className={`ht-map-label ${highlight ? 'highlight' : ''} ${animationState.color}`}
        style={{
          top: animationState.labelTop,
          left: animationState.labelLeft,
          opacity: opacity,
        }}
        dangerouslySetInnerHTML={{ __html: animationState.label }}
      />
    </>
  );
}
