import React from "react";
import { Link } from "gatsby";

import { marked } from "marked";

import NarrativeSidebar from "./narrative-sidebar";
import SidebarNote from "./sidebar-note";
import JukeboxCollection from "./popup-jukebox/jukebox-collection";
import Image from "./image";

function insertNotes(content, notes) {
  const re = /\{\{note:([\S]*)\}\}/g;
  let formattedContent = content;
  let match;

  if (!notes) {
    return formattedContent;
  }

  while ((match = re.exec(formattedContent)) !== null) {
    // eslint-disable-next-line
    const num = notes.findIndex(note => note.reference === match[1]) + 1;

    formattedContent = formattedContent.replace(match[0],
      `<a id="note-${num}-link" class="note" href="#note-${num}">${num}</a>`
    );
  }

  return formattedContent;
}

function generateInternalLink(linkTag, key) {
  const iconDict = {
    "venue": "fa-map-marker",
    "artist": "fa-users",
  };

  const re = new RegExp(`\\{\\{([\\w]*):([\\S]*) \\(([\\w ’‘”“.]*)\\)\\}\\}`, 'g');
  const matches = re.exec(linkTag);

  if (!matches) {
    return;
  }

  const linkType = matches[1];
  const linkTarget = matches[2];
  const linkLabel = matches[3];

  return (
    <Link key={key} className={`internal-link ${linkType}`}
          to={`/${linkType}s/${linkTarget}`}>
      {linkLabel}{' '}
      <i className={`fas ${iconDict[linkType]}`} />
    </Link>
  );
}

function componentizeParagraphText(formattedContent) {
  // Make span components of all non-link text runs
  const textComponents =
                formattedContent.split(/\{\{[^}}]*\}\}/g)
                  .map((el, idx) => (
                      <span key={idx} dangerouslySetInnerHTML={{__html: el}}  />
                  ));

  // Collect Link Tags
  let linkComponents = [];
  let match;
  const re = /\{\{[^}}]*\}\}/g;
  while ((match = re.exec(formattedContent)) !== null) {
    linkComponents.push(match[0]);
  }

  // Zip together text and link components
  let contentComponents = [];
  textComponents.forEach((el, idx) => {
    contentComponents.push(el);
    if (idx < linkComponents.length) {
      contentComponents.push(generateInternalLink(linkComponents[idx], `${idx}-${contentComponents.length}`));
    }
  });

  // return array of zippered text and link components
  return contentComponents;
}

function insertMediaLinks(content, linkType, reference) {
  const iconDict = {
    "image": "fa-image",
    "video": "fa-film",
  };

  const re = new RegExp(`\\{\\{${linkType}:([\\S]*) \\(([\\w ’‘“”.]*)\\)\\}\\}`,'g');
  let formattedContent = content;
  let match;

  while ((match = re.exec(formattedContent)) !== null) {
    let path, linkClass;
    switch (linkType) {
      case "image":
        path = `/images/narratives/${reference}/${match[1]}`;
        linkClass = "image";
        break;
      case "youtube":
        path = `https://www.youtube.com/watch?v=${match[1]}`;
        linkClass = "video";
        break;
      default:
        break;
    }
    const link = `<a href="${path}" ` +
                 `class="internal-link ${linkClass}" data-fancybox>` +
                 `${match[2]} <i class="fas ${iconDict[linkClass]}"></i></a>`;

    formattedContent = formattedContent.replace(match[0], link);
  }

  return formattedContent;
}


export default (props) => {
  let elements = [];
  let narrativeSidebars = [];
  let sidebarImages = [];
  let sidebarNotes = [];
  let popupJukebox = [];


  if (props.attachments) {
    narrativeSidebars = props.attachments.filter(el => el.type === 'narrative-sidebar');
    sidebarImages = props.attachments.filter(el => el.type === 'image');
    sidebarNotes = props.attachments.filter(el => el.type === 'sidebar-note');
    popupJukebox = props.attachments.filter(el => el.type === 'popup-jukebox');
  }

  let formattedContent = marked(props.content.text);
  formattedContent = formattedContent.replace('<p>', '');
  formattedContent = formattedContent.replace('</p>', '');

  // Perform HTML-only manipulations
  formattedContent = insertNotes(formattedContent, props.notes);
  ['image', 'youtube'].forEach(
    (linkType) => {
      formattedContent = insertMediaLinks(formattedContent,
                                          linkType, props.reference);
    }
  );

  // Take formatted text and break it into components of plain text runs
  // interspersed with special internal links
  const contentComponents = componentizeParagraphText(formattedContent);
  const hasSidebar = (sidebarNotes.length > 0) || (popupJukebox.length > 0) || (sidebarImages.length > 0);
  let paragraphClasses = hasSidebar ? 'with-sidebar' : '';

  if (props.meta) {
    paragraphClasses += ' meta-paragraph';
  }

  if (props.preformatted) {
    elements.push((
      <div className={`main-content ${hasSidebar ? 'with-sidebar' : ''}`}>
        {narrativeSidebars.map(el => (
          <NarrativeSidebar narrativeReference={el.target}
            exhibitReference={el.exhibit}
            label={el.content.text}
            key={elements.length} />
        ))}
        <pre key={elements.length} className={paragraphClasses}>
          {contentComponents}
        </pre>
      </div>
    ));
  } else {
    elements.push((
      <div className={`main-content ${hasSidebar ? 'with-sidebar' : ''}`}>
        {narrativeSidebars.map(el => (
          <NarrativeSidebar narrativeReference={el.target}
            exhibitReference={el.exhibit}
            label={el.content.text}
            key={elements.length} />
        ))}
        <p key={elements.length} className={paragraphClasses}>
          {contentComponents}
        </p>
      </div>
    ));
  }

  const sidebarImageSet = (sidebarImages.length > 0) ? (
    <div className="sidebar-note">
      {sidebarImages.map((el, idx) => (
        <Image content={el.content}
               reference={props.reference}
               allImages={props.allImages}
               key={idx} />
      ))}
    </div>
  ) : null;

  const sidebarNoteSet = (sidebarNotes.length > 0) ? (
    <div>
      {sidebarNotes.map((el, idx) => {
        let formattedContent = marked(el.content.text);
        formattedContent = formattedContent.replace('<p>', '');
        formattedContent = formattedContent.replace('</p>', '');

        formattedContent = insertNotes(formattedContent, props.notes);

        return (<SidebarNote content={formattedContent} key={idx} />);
      })}
    </div>
  ) : null;

  const popupJukeboxSet = (popupJukebox.length > 0) ? (
    <div className="sidebar-note">
      <JukeboxCollection content={popupJukebox[0].content}
                        launchPopupJukebox={props.narrative.launchPopupJukebox} />
    </div>
  ) : null;

  if (sidebarImages.length + sidebarNotes.length + popupJukebox.length > 0) {
    elements.push((
      <div key={elements.length} className="sidebar-box">
        {sidebarImageSet}
        {sidebarNoteSet}
        {popupJukeboxSet}
      </div>
    ));
  }

  return (
    <div className={`paragraph-wrapper ${props.embeddedLayout ? ' embedded-layout' : ''}`}>
      {elements}
    </div>
  );
}
