import React from "react";

import ContentGroup from "./content-group";

export default (props) => {
  const content = (<ContentGroup contentBlocks={props.contentBlocks}
                                 notes={props.notes}
                                 reference={props.reference}
                                 allImages={props.allImages}
                                 embeddedLayout={ true } />);

  return (
    <blockquote>
    {content}
    </blockquote>
  )
}
