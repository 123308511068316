import React, { useEffect, useRef, useState } from 'react';
import { Scrollama, Step } from 'react-scrollama';

import { allAnimationStates } from './animation-states';
import LocationMarker from './location-marker';

import './honkytonkin-venues-scrollama.scss';

import openColor from 'open-color/open-color';

const stepBlockStyles = {
  // borderColor: 'yellow',
  // borderStyle: 'dashed',
  // borderWidth: '2px 0 0 0',
};

export default props => {
  const animationStates = allAnimationStates[props.prefix];

  const [step, setStep] = useState(0);

  const mapBackgroundRef = useRef();

  useEffect(() => {
    const manageMapSize = () => {
      const height = mapBackgroundRef.current.offsetHeight;
      const width = height * props.aspectRatio;

      mapBackgroundRef.current.style.width = `${width}px`;
    };
    manageMapSize();
    // set resize listener
    window.addEventListener('resize', manageMapSize);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', manageMapSize);
    }
  }, []);

  const visibilityStates = (step > animationStates.length - 1)
  ? animationStates[animationStates.length - 1]['visibility']
  : animationStates[step]['visibility'];

  return (
    <div className="honkytonkin-venues-wrapper">
      <div className="honkytonkin-venues-frame">
        <div
          className="ht-map-block"
          style={{
            backgroundImage: `url(${props.backgroundImage})`,
          }}
          ref={ mapBackgroundRef }
        >
          {animationStates.map(animationState => {
            if (animationState.type === 'location') {
              return (
                <LocationMarker
                  animationState={ animationState }
                  visibilityStates={ visibilityStates }
                />
              );
            }

            return null;
          })}
        </div>
      </div>
      <div style={{ position: 'relative', zIndex: 1 }}>
        {typeof(window) !== 'undefined' &&
          <Scrollama
            prefix={ props.prefix }
            offset={ 0.8 }
            onStepEnter={ ({ data }) => setStep(data) }
            onStepExit={ ({ data, direction }) => {
              if (data === 1 && direction === 'up') {
                setStep(0)
              }
            }}
            debug={ false }
          >
            {animationStates.map((el, idx) => {
              let styles = Object.assign({}, stepBlockStyles);
              styles['height'] = (el['duration'] === 'short') ? '22.5vh' : '90vh';

              return (
                <Step data={idx + 1}>
                  <div
                    style={ styles }
                  >
                    {(animationStates[idx].type === 'text') && (
                      <div
                        className="ht-text-block"
                        dangerouslySetInnerHTML={{ __html: animationStates[idx].content }}
                      />
                    )}
                  </div>
                </Step>
              );
            })}
          </Scrollama>
        }
      </div>
    </div>
  );
}
