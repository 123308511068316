import React from "react";

import Timeline from "./timeline";

class TimelineGroup extends React.Component {
  render() {
    return (
      <div className="month-wrapper">
        <p className="month-header">
          {this.props.timelineGroup.month}
        </p>
        {(this.props.timelineGroup.timelines) ? (
          <>
            {(this.props.timelineGroup.timelines.map(timeline => (
              <Timeline 
                timeline={timeline}
                dateClick={this.props.dateClick}
              />
            )))}
          </>
        ) :
        (
          <div className="empty-group">•••</div>
        )}

      </div>
    );
  }
}

export default TimelineGroup;