import React, { useEffect, useRef } from 'react';

const colors = {
  // https://yeun.github.io/open-color/#blue
  'pastPop': '#e7f5ff', // Blue #0
  'newPop': '#d0ebff', // Blue #1
};

const data = [
  { date: '1900', pastPop: 22258, newPop: 0, currentPop: 22258, percentage: null },
  { date: '1910', pastPop: 22258, newPop: 7602, currentPop: 29860, percentage: 34.15 },
  { date: '1920', pastPop: 29860, newPop: 5016, currentPop: 34876, percentage: 16.80 },
  { date: '1930', pastPop: 34876, newPop: 18244, currentPop: 53120, percentage: 52.31 },
  { date: '1940', pastPop: 53120, newPop: 34810, currentPop: 87930, percentage: 65.53 },
  { date: '1950', pastPop: 87930, newPop: 44529, currentPop: 132459, percentage: 50.64 },
  { date: '1960', pastPop: 132459, newPop: 54086, currentPop: 186545, percentage: 49.83 },
  { date: '1970', pastPop: 186545, newPop: 65263, currentPop: 251808, percentage: 34.99 },
  { date: '1980', pastPop: 251808, newPop: 93688, currentPop: 345496, percentage: 37.21 },
  { date: '1990', pastPop: 345496, newPop: 126524, currentPop: 472020, percentage: 36.62 },
  { date: '2000', pastPop: 472020, newPop: 184542, currentPop: 656562, percentage: 39.10 },
];

const drawD3 = (chartRef, wrapperRef) => {
  const d3 = require('d3');

  const margin = {top: 10, right: 60, bottom: 20, left: 35};

  const w = wrapperRef.current.offsetWidth;
  const h = w * 2 / 3;

  const chart = d3.select(chartRef.current);

  chart.select('svg').remove();

  const svg = chart.append('svg').attr('width', w).attr('height', h).attr('preserveAspectRatio', 'xMinYMin meet').attr('viewBox', `0 0 ${w} ${h}`).append('g');

  const series = d3.stack()
  .keys(['pastPop', 'newPop'])(data)
    .map(d => d);

  const x = d3.scaleBand()
    .domain(data.map(d => d.date))
    .range([margin.left, w - margin.right])
    .padding(0.1);

  const populationY = d3.scaleLinear()
    .domain([0, d3.max(data, d => d.currentPop)])
    .rangeRound([h - margin.bottom, margin.top]);

  const percentageY = d3.scaleLinear()
    .domain([0, 100])
    .rangeRound([h - margin.bottom, margin.top]);

  const xAxis = (g) => {
    g.attr('transform', `translate(0, ${h - margin.bottom})`)
    .call(d3.axisBottom(x).tickSizeOuter(0));
  };

  const yLeftAxis = (g) => {
    g.attr('transform', `translate(${margin.left}, 0)`)
    .call(d3.axisLeft(percentageY).ticks(null, 's').tickFormat(val => `${val}%`))
    .call(g => g.selectAll('.domain').remove());
  };

  const yRightAxis = (g) => {
    g.attr("transform", `translate(${w - margin.right}, 0)`)
    .call(d3.axisRight(populationY))
    .call(g => g.select('.tick:first-of-type').remove())
    .call(g => g.select(".domain").remove())
  };

  const line = d3.line()
    .defined(d => d.percentage)
    .x(d => x(d.date) + (x.bandwidth() / 2))
    .y(d => percentageY(d.percentage));

  svg.append('g')
    .selectAll('g')
    .data(series)
    .join('g')
      .attr('fill', d => colors[d.key])
    .selectAll('rect')
    .data(d => d)
    .join('rect')
      .attr('x', (d, i) => x(d.data.date))
      .attr('y', d => populationY(d[1]))
      .attr('height', d => populationY(d[0]) - populationY(d[1]))
      .attr('width', x.bandwidth());

  svg.append('path')
    .datum(data)
    .attr('fill', 'none')
    .attr('stroke', '#fd7e14') // Orange 6, https://yeun.github.io/open-color/#blue
    .attr('stroke-width', 4)
    .attr('stroke-linejoin', 'round')
    .attr('stroke-linecap', 'round')
    .attr('d', line);

  svg.append('g')
    .call(xAxis);

  svg.append('g')
    .call(yLeftAxis);

  svg.append('g')
    .call(yRightAxis);

};

export default (props) => {
  const chartRef = useRef();
  const wrapperRef = useRef();

  useEffect(() => {
    drawD3(chartRef, wrapperRef);

    window.addEventListener('resize', (e) => {
      drawD3(chartRef, wrapperRef);
    });
  }, []);

  return (
    <div className="demographics-viz-stacked-bar-chart-wrapper" ref={ wrapperRef }>
      <div id={ props.chartId } ref={ chartRef } class="chart-container"></div>
    </div>
  );
};
