import React from "react";

import moment from "moment";

import dates from "./dates";

class Timeline extends React.Component {
  checkForEventOnDate(date) {
    const dateMatch = dates.filter(d => d['date'] === date);

    return dateMatch.length > 0;
  }

  render() {
    return (
      <ul className="timeline">
        {(this.props.timeline.dates.map((date, idx) => {
          const backgroundColor = (idx < this.props.timeline.dates.length - 1) ?
                  this.props.timeline.color : 'transparent';

          return (
            <li
              key={idx}
              style={{backgroundColor: backgroundColor}}
            >
              {(this.checkForEventOnDate(date)) ? (
                <a href="#" className="date-bubble"
                  style={{backgroundColor: this.props.timeline.color}}
                  onClick={(e) => this.props.dateClick(e, date)}
                >
                  {moment(date).format('D')}
                </a>
              ) :
              (
                <span className="date-bubble"
                  style={{color: '#212529', backgroundColor: '#dee2e6'}}
                >
                  {moment(date).format('D')}
                </span>
              )}
            </li>
          );
        }))}
      </ul>
    );
  }
}

export default Timeline;