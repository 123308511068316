import React from "react";

import ContentGroup from "./content-group";

import "../../scss/components/narratives/scrolling-block.scss";

export default (props) => (
  <div className="scrolling-block">
    <div className="scrolling-block__left-side">
      <div className="scrolling-block__sticky-frame">
        <ContentGroup contentBlocks={props.leftSideContent.contentBlocks}
                      notes={props.notes}
                      reference={props.reference}
                      allImages={props.allImages}
                      embeddedLayout={ true } />
      </div>
    </div>
    <div className="scrolling-block__right-side">
      <ContentGroup contentBlocks={props.rightSideContent.contentBlocks}
                    notes={props.notes}
                    reference={props.reference}
                    allImages={props.allImages}
                    embeddedLayout={ true } />
    </div>
  </div>
);
