import React from "react";

export default (props) => {
  const images = props.content.images.map((image) => {
    const reference = image.reference;

    const imageResult = props.allImages.filter((image) => {
      return (image.node.reference === reference);
    });

    if (imageResult && imageResult.length > 0) {
      return imageResult[0].node;
    }

    return null;
  });
  const content = images.map((image, idx) => (
    <li key={idx}>
      <a data-fancybox={props.content.galleryName}
        href={`/images/content/${image.fullPath}`}>
        <img
          src={`/images/content/${image.thumbPath}`}
          alt={image.alt} />
      </a>
    </li>
   ));

  return (
    <div className="image-thumbnail-gallery">
      <ul>
        {content}
      </ul>
    </div>
  )
}