import React, { useEffect, useRef } from 'react';

const data = [
  { date: new Date('1900-01-01'), population: 22258 },
  { date: new Date('1910-01-01'), population: 29860 },
  { date: new Date('1920-01-01'), population: 34876 },
  { date: new Date('1930-01-01'), population: 53120 },
  { date: new Date('1940-01-01'), population: 87930 },
  { date: new Date('1950-01-01'), population: 132459 },
  { date: new Date('1960-01-01'), population: 186545 },
  { date: new Date('1970-01-01'), population: 251808 },
  { date: new Date('1980-01-01'), population: 345496 },
  { date: new Date('1990-01-01'), population: 472020 },
  { date: new Date('2000-01-01'), population: 656562 },
];

const drawD3 = (chartRef, wrapperRef) => {
  const d3 = require('d3');

  const margin = {top: 10, right: 20, bottom: 30, left: 50 };

  const w = wrapperRef.current.offsetWidth;
  const h = w * 2 / 3;

  const chart = d3.select(chartRef.current);

  chart.select('svg').remove();

  const svg = chart.append('svg').attr('width', w).attr('height', h).attr('class', 'shadow').attr('preserveAspectRatio', 'xMinYMin meet').attr('viewBox', `0 0 ${w} ${h}`).append('g');

  const x = d3.scaleTime()
      .domain([new Date('1900-01-01'), new Date('2000-01-02')])
      .range([margin.left, w - margin.right]);

  const y = d3. scaleLinear()
      .domain([0, 656562])
      .range([h - margin.bottom, margin.top]);

  const line = d3.line()
          .defined(d => !isNaN(d.population))
          .x(d => x(d.date))
          .y(d => y(d.population));

  const xAxis = (g) => {
    g.attr("transform", `translate(0, ${h - margin.bottom})`)
      .call(d3.axisBottom(x).ticks(w / 80).tickSizeOuter(0));
  };

  const yAxis = (g) => {
    g.attr("transform", `translate(${margin.left}, 0)`)
      .call(d3.axisLeft(y))
      .call(g => g.select('.tick:first-of-type').remove())
      .call(g => g.select(".domain").remove())
  };

  svg.append("g")
      .call(yAxis);

  svg.append("g")
      .call(xAxis);

  svg.append('path')
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", '#228be6')   // 'Blue 6' https://yeun.github.io/open-color
      .attr("stroke-width", 2)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("d", line);
};

export default (props) => {
  const chartRef = useRef();
  const wrapperRef = useRef();

  useEffect(() => {
    drawD3(chartRef, wrapperRef);

    window.addEventListener('resize', e => {
      drawD3(chartRef, wrapperRef);
    });
  }, []);

  return (
    <div className="demographics-viz-line-chart-wrapper" ref={ wrapperRef }>
      <div id={ props.chartId } ref={ chartRef } class="chart-container"></div>
    </div>
  );
};
