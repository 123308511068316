import React from "react";

import CountBasie1941 from "./custom-blocks/count-basie-1941/count-basie-1941";
import Economics1930s from "./custom-blocks/economics-1930s/economics-1930s";
import GardnerTour1930 from "./custom-blocks/gardner-tour-1930/gardner-tour-1930";
import {
  Demographics1940sChart1, Demographics1940sChart2, Demographics1940sChart3,
} from './custom-blocks/demographics-1940s/demographics-1940s';
import HonkytonkinVenuesScrollama from './custom-blocks/honkytonkin-venues-scrollama/honkytonkin-venues-scrollama';
import MilitaryBasesMap from './custom-blocks/military-bases-map/military-bases-map';
import NationalBandsTimeline from "./custom-blocks/national-bands-timeline/national-bands-timeline";
import UTDancesMap from "./custom-blocks/ut-dances-map/ut-dances-map";

export default (props) => {
  switch (props.content.reference) {
    case 'ut-dances-map':
      return (<UTDancesMap />);
    case 'national-bands-timeline':
      return (<NationalBandsTimeline />);
    case 'gardner-tour-1930':
      return (<GardnerTour1930 />);
    case 'count-basie-1941':
      return (<CountBasie1941 />);
    case 'economics-1930s':
      return (<Economics1930s />);
    case 'ballroom-venues-scrollama':
      return (
        <HonkytonkinVenuesScrollama
          aspectRatio={ 0.6697551291 }
          backgroundImage={ '/images/content/a/Austin_Map_1943.jpg' }
          prefix={ 'ballrooms' }
        />
      );
    case 'honkytonk-venues-scrollama':
      return (
        <HonkytonkinVenuesScrollama
          aspectRatio={ 0.649967887 }
          backgroundImage={ '/images/content/t/Travis_County_1940.jpg' }
          prefix={ 'honkytonks' }
        />
      );
    case 'turnover-venues-scrollama':
      return (
        <HonkytonkinVenuesScrollama
          aspectRatio={ 0.649967887 }
          backgroundImage={ '/images/content/t/Travis_County_1940.jpg' }
          prefix={ 'turnover' }
        />
      );
    case 'segregation-venues-scrollama':
      return(
        <HonkytonkinVenuesScrollama
          aspectRatio={ 0.649967887 }
          backgroundImage={ '/images/content/t/Travis_County_1940.jpg' }
          prefix={ 'segregation' }
        />
      );
    case 'military-bases-map':
      return <MilitaryBasesMap />;
    case '1940s-demographics-chart-1':
      return <Demographics1940sChart1 />;
    case '1940s-demographics-chart-2':
      return <Demographics1940sChart2 />;
    case '1940s-demographics-chart-3':
      return <Demographics1940sChart3 />;
    default:
      return null;
  }
}
