import React from "react";

import moment from "moment";

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.dateClasses = this.dateClasses.bind(this);
    this.eventOnDate = this.eventOnDate.bind(this);
  }

  dateClasses(date) {
    let dateClasses = 'day ';
    let dateIndex = null;

    dateClasses += (date) ? 'dated-day ' : 'empty-day ';

    this.props.events.forEach((event, idx) => {
      if (event['date'] === date && event['city']) {
        dateIndex = idx;
      }
    });
    
    dateClasses += (dateIndex === this.props.animationProgress) ? 'highlight ' : '';

    return dateClasses;
  }

  eventOnDate(date) {
    const dateMatch = this.props.events.filter(event => event['date'] === date && event['city']);

    return dateMatch.length > 0;
  }

  render() {
    return (
      <div className="calendar">
        <div className="header">
          {this.props.calendar.headline}
        </div>
        <div className="grid">
          {this.props.calendar.dates.map((date, idx) => (
            <div className={this.dateClasses(date)} key={idx}>
              {(date) ? (
                <div className="content">
                  {(this.eventOnDate(date)) ? (
                    <a href="#"
                       onClick={(e) => {
                          e.preventDefault();
                          this.props.jumpToDate(date);
                       }}
                    >
                      {moment(date).format('D')}
                    </a>
                  ) : (
                    <span className="date-label">
                      {moment(date).format('D')}
                    </span>
                  )}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Calendar;