import React from "react";

import { Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import DataTable from './data-table';
import DonutChart from './donut-chart';

import "./economics-1930s.scss";

class Economics1930s extends React.Component {
  constructor(props) {
    super(props);

    this.ethnicity_data = {
      'all': { 'unknown': 64, 'white': 452, 'black': 15, 'latino': 3},
      'sfa-hotel': { 'unknown': 2, 'white': 81, 'black': 0, 'latino': 0},
      'texas-union': { 'unknown': 30, 'white': 170, 'black': 5, 'latino': 0},
      'gregory-gym': { 'unknown': 15, 'white': 159, 'black': 10, 'latino': 3},
      'austin-country-club': { 'unknown': 17, 'white': 42, 'black': 0, 'latino': 0},
      '1930': {'unknown': 6, 'white': 29, 'black': 0, 'latino': 0}, 
      '1931': {'unknown': 3, 'white': 30, 'black': 1, 'latino': 0}, 
      '1932': {'unknown': 7, 'white': 31, 'black': 1, 'latino': 1}, 
      '1933': {'unknown': 2, 'white': 87, 'black': 4, 'latino': 0}, 
      '1934': {'unknown': 8, 'white': 40, 'black': 0, 'latino': 0}, 
      '1935': {'unknown': 15, 'white': 69, 'black': 0, 'latino': 0}, 
      '1936': {'unknown': 7, 'white': 36, 'black': 3, 'latino': 0}, 
      '1937': {'unknown': 2, 'white': 65, 'black': 4, 'latino': 1}, 
      '1938': {'unknown': 3, 'white': 29, 'black': 2, 'latino': 1}, 
      '1939': {'unknown': 11, 'white': 36, 'black': 0, 'latino': 0}
    };

    this.locations = [
      {
        id: 'sfa-hotel',
        label: 'Stephen F. Austin Hotel',
        labelPosition: 'right',
        coords: [30.2690545, -97.7441772],
      },
      {
        id: 'austin-country-club',
        label: 'Austin Country Club',
        labelPosition: 'left',
        coords: [30.2991872, -97.7266377],
      },
      {
        id: 'gregory-gym',
        label: 'Gregory Gym',
        labelPosition: 'right',
        coords: [30.284103, -97.736530],
      },
      {
        id: 'texas-union',
        label: 'Texas Union',
        labelPosition: 'left',
        coords: [30.286744, -97.741176],
      },
    ];

    this.mapRef = React.createRef();
    this.mapWrapperRef = React.createRef();

    this.drawPoints = this.drawPoints.bind(this);
    this.fitMap = this.fitMap.bind(this);
  }

  componentDidMount() {
    this.fitMap();
    this.drawPoints();

    window.addEventListener('resize', this.fitMap);
  }

  drawPoints() {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    const markers = this.locations.map(location => {
      const textLabel = L.divIcon({ className: 'econ-viz-map-label',
                                    html: `<span class="contents ${location.labelPosition}">${location.label}</span>`});
      const circle = L.circleMarker(
                      location.coords,
                      {
                        color: '#fd7e14',
                        stroke: true,
                        weight: 2,
                        radius: 12,
                        fillOpacity: .8,
                      });
      const label = L.marker(location.coords, { icon: textLabel });

      circle.addTo(map);
      label.addTo(map);
      
      // TODO: Add click events to circle and label
    });
  }

  fitMap() {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    const allPoints = this.locations.map(location => location.coords);

    map.fitBounds(L.polyline(allPoints).getBounds());
  }

  render() {
    if (typeof window !== 'undefined') {
      return (
        <div className="econ-viz-display-wrapper">
          <div class="econ-viz-display-wrapper__top-row">
            <DonutChart
              dataset={[
                { label: 'White', percent: 84.64 },
                { label: 'Black', percent: 2.80 },
                { label: 'Latino', percent: 0.56 },
                { label: 'Unknown', percent: 11.98 }
              ]}
            />
            <DataTable 
              big={true}
              dataset={this.ethnicity_data['all']}
            />
            <div className="econ-viz-map-wrapper" ref={this.wrapperRef}>
              <Map ref={this.mapRef}
                className="map"
                dragging={false}
                boxZoom={false}
                zoomControl={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                touchZoom={false}
              >
                <TileLayer
                  url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                />
              </Map>
            </div>
          </div>
          <div className="econ-viz-display-wrapper__bottom-row">
            <div className="cell">
              <div className="header">
                Gregory Gym
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 85.03 },
                    { label: 'Black', percent: 5.35 },
                    { label: 'Latino', percent: 1.6 },
                    { label: 'Unknown', percent: 8.02 }
                  ]}
                />
                <DataTable
                  dataset={this.ethnicity_data['gregory-gym']}
                />
              </div>
            </div>
            <div className="cell">
              <div className="header">
                Texas Union
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 82.93 },
                    { label: 'Black', percent: 2.44 },
                    { label: 'Latino', percent: 0 },
                    { label: 'Unknown', percent: 14.63 }
                  ]}
                />
                <DataTable
                  dataset={this.ethnicity_data['texas-union']}
                />
              </div>
            </div>
            <div className="cell">
              <div className="header">
                Austin Country Club
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 71.19 },
                    { label: 'Black', percent: 0 },
                    { label: 'Latino', percent: 0 },
                    { label: 'Unknown', percent: 28.81 }
                  ]}
                />
                <DataTable
                  dataset={this.ethnicity_data['austin-country-club']}
                />
              </div>
            </div>
            <div className="cell">
              <div className="header">
                Stephen F. Austin Hotel
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 97.59 },
                    { label: 'Black', percent: 0 },
                    { label: 'Latino', percent: 0 },
                    { label: 'Unknown', percent: 2.41 }
                  ]}
                />
                <DataTable
                  dataset={this.ethnicity_data['sfa-hotel']}
                />
              </div>
            </div>
            <div className="divider"><hr /></div>
            <div className="cell">
              <div className="header">
                  1930
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 82.85 },
                    { label: 'Black', percent: 0 },
                    { label: 'Latino', percent: 0 },
                    { label: 'Unknown', percent: 17.14 }
                  ]}
                />
                <DataTable
                  dataset={this.ethnicity_data['1930']}
                />
              </div>
            </div>
            <div className="cell">
              <div className="header">
                  1931
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 88.24 },
                    { label: 'Black', percent: 2.94 },
                    { label: 'Latino', percent: 0 },
                    { label: 'Unknown', percent: 8.82 }
                  ]}
                />

                <DataTable
                  dataset={this.ethnicity_data['1931']}
                />
              </div>
            </div>
            <div className="cell">
              <div className="header">
                  1932
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 77.5 },
                    { label: 'Black', percent: 2.5 },
                    { label: 'Latino', percent: 2.5 },
                    { label: 'Unknown', percent: 17.5 }
                  ]}
                />

                <DataTable
                  dataset={this.ethnicity_data['1932']}
                />
              </div>
            </div>
            <div className="cell">
              <div className="header">
                  1933
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 93.55 },
                    { label: 'Black', percent: 4.3 },
                    { label: 'Latino', percent: 0 },
                    { label: 'Unknown', percent: 2.15 }
                  ]}
                />

                <DataTable
                  dataset={this.ethnicity_data['1933']}
                />
              </div>
            </div>
            <div className="cell">
              <div className="header">
                  1934
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 83.33 },
                    { label: 'Black', percent: 0 },
                    { label: 'Latino', percent: 0 },
                    { label: 'Unknown', percent: 16.67 }
                  ]}
                />

                <DataTable
                  dataset={this.ethnicity_data['1934']}
                />
              </div>
            </div>
            <div className="cell">
              <div className="header">
                  1935
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 82.14 },
                    { label: 'Black', percent: 0 },
                    { label: 'Latino', percent: 0 },
                    { label: 'Unknown', percent: 17.86 }
                  ]}
                />

                <DataTable
                  dataset={this.ethnicity_data['1935']}
                />
              </div>
            </div>
            <div className="cell">
              <div className="header">
                  1936
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 78.26 },
                    { label: 'Black', percent: 6.52 },
                    { label: 'Latino', percent: 0 },
                    { label: 'Unknown', percent: 15.22 }
                  ]}
                />

                <DataTable
                  dataset={this.ethnicity_data['1936']}
                />
              </div>
            </div>
            <div className="cell">
              <div className="header">
                  1937
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 90.28 },
                    { label: 'Black', percent: 5.56 },
                    { label: 'Latino', percent: 1.39 },
                    { label: 'Unknown', percent: 2.78 }
                  ]}
                />

                <DataTable
                  dataset={this.ethnicity_data['1937']}
                />
              </div>
            </div>
            <div className="cell">
              <div className="header">
                  1938
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 82.86 },
                    { label: 'Black', percent: 5.71 },
                    { label: 'Latino', percent: 2.86 },
                    { label: 'Unknown', percent: 8.57 }
                  ]}
                />

                <DataTable
                  dataset={this.ethnicity_data['1938']}
                />
              </div>
            </div>
            <div className="cell">
              <div className="header">
                  1939
              </div>
              <div>
                <DonutChart
                  dataset={[
                    { label: 'White', percent: 76.60 },
                    { label: 'Black', percent: 0 },
                    { label: 'Latino', percent: 0 },
                    { label: 'Unknown', percent: 23.40 }
                  ]}
                />

                <DataTable
                  dataset={this.ethnicity_data['1939']}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="econ-viz-display-wrapper">
      </div>
    );
  }
}

export default Economics1930s;