import React, { useEffect, useRef } from 'react';

const drawD3 = (minY, maxY, data, chartRef, wrapperRef, suppressLegend) => {
  const d3 = require('d3');

  const margin = {top: 10, right: 10, bottom: 20, left: 50};

  const w = wrapperRef.current.offsetWidth;
  const h = w * 2 / 3;

  const chart = d3.select(chartRef.current);

  chart.select('svg').remove();

  const svg = chart.append('svg').attr('width', w).attr('height', h).attr('preserveAspectRatio', 'xMinYMin meet').attr('viewBox', `0 0 ${w} ${h}`).append('g');

  const keys = ['manufacturing', 'retail', 'service', 'household'];
  const legendLabels = {
    manufacturing: "Average Wage: Manufacturing",
    retail: "Average Wage: Retail",
    service: "Average Wage: Service",
    household: "Median Household Income",
  };

  const color = d3.scaleOrdinal()
      .range(['#228be6', '#40c057', '#fd7e14', '#be4bdb']);

  const legend = (svg) => {
    const g = svg
        .attr('transform', `translate(${margin.left + 20}, 0)`)
        .attr('text-anchor', 'start')
        .attr('font-family', "'IBM Plex Sans', sans-serif")
        .attr('font-size', 10)
      .selectAll('g')
      .data(color.domain().slice())
      .join('g')
        .attr('transform', (d, i) => `translate(0, ${i * 20})`);

    g.append('rect')
        .attr('x', 0)
        .attr('width', 19)
        .attr('height', 19)
        .attr('fill', color);

    g.append('text')
        .attr('x', 24)
        .attr('y', 9.5)
        .attr('dy', '0.35em')
        .text(d => legendLabels[d]);
  };

  const x0 = d3.scaleBand()
            .domain(data.map(d => d.year))
            .rangeRound([margin.left, w - margin.right])
            .paddingInner(0.1);

  const x1Gutter = 0.02 * x0.bandwidth();
  const x1Col = (x0.bandwidth() - (3 * x1Gutter)) / 4;
  const x1 = d3.scaleOrdinal()
            .domain(keys)
            .range([0, x1Col + x1Gutter, (2 * x1Col) + (2 * x1Gutter), (3 * x1Col) + (3 * x1Gutter)]);

  const y = d3.scaleLinear()
            .domain([minY, maxY])
            .rangeRound([h - margin.bottom, margin.top]);

  const xAxis = (g) => {
    g.attr('transform', `translate(0, ${h - margin.bottom})`)
     .call(d3.axisBottom(x0).tickSizeOuter(0));
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });
  const yAxis = (g) => {
    g.attr('transform', `translate(${margin.left}, 0)`)
     .call(d3.axisLeft(y).ticks(null, 's').tickFormat(val => formatter.format(val)))
     .call(g => g.select('.domain').remove())
     .call(g => g.select('.tick:last-of-type text').clone()
          .attr('x', 3)
          .attr('text-anchor', 'start')
          .attr('font-weight', 'bold')
          .text(data.y));
  };

  const x0Offset = (d) => {
    const columnCount = keys.reduce((acc, val) => (d[val]) ? acc + 1 : acc, 0);

    return x0(d.year);
  };

  const x1Offset = (key, group) => {
    const columnCount = keys.reduce((acc, key) => (group[key]) ? acc + 1 : acc, 0);
    const availableColumns = keys.filter(key => group[key]);

    if (availableColumns.indexOf(key) >= 0) {
      return x1(availableColumns.indexOf(key)) + ((x0.bandwidth() - (columnCount * x1Col) - ((columnCount - 1) * x1Gutter)) / 2);
    }

    return 0;
  };

  svg.append('g')
    .selectAll('g')
    .data(data)
    .join('g')
      .attr('transform', d => `translate(${x0Offset(d)}, 0)`)
    .selectAll('rect')
    .data(d => keys.map(key => ({key, value: d[key], group: d})))
    .join('rect')
      .attr('x', d => x1Offset(d.key, d.group))
      .attr('y', d => y(d.value))
      .attr('width', x1Col)
      .attr('height', d => y(minY) - y(d.value))
      .attr('fill', d => color(d.key));

  svg.append('g')
      .call(xAxis);

  svg.append('g')
      .call(yAxis);

  if (!suppressLegend) {
    svg.append('g')
        .call(legend);
  }
};

export default (props) => {
  const chartRef = useRef();
  const wrapperRef = useRef();

  useEffect(() => {
    drawD3(props.minY, props.maxY, props.wageData, chartRef, wrapperRef, props.suppressLegend);

    window.addEventListener('resize', e => {
      drawD3(props.minY, props.maxY, props.wageData, chartRef, wrapperRef, props.suppressLegend);
    });
  }, []);

  return (
    <div className="demographics-viz-grouped-bar-chart-wrapper" ref={ wrapperRef }>
      <div ref={ chartRef } class="chart-container"></div>
    </div>
  );
};
