import React from "react";

import Paragraph from "./paragraph";
import MajorSubhead from "./major-subhead";
import MinorSubhead from "./minor-subhead";
import PullQuote from "./pull-quote";
import Divider from "./divider";
import Image from "./image";
import ImageGallery from "./image-gallery";
import Blockquote from "./blockquote";
import Video from "./video";
import SimpleMap from "./simple-map";
import Offset from "./offset";
import ScrollingBlock from "./scrolling-block";
import CustomBlock from "./custom-block";
import JukeboxCollection from "./popup-jukebox/jukebox-collection";
import AudioPlayerList from "./audio-player";

function assembleContent(contentBlocks, notes, reference, narrative, allImages, embeddedLayout) {
  return contentBlocks.map((block, idx) => {
    switch (block.type) {
      case "paragraph":
      case "preformatted-paragraph":
      case "meta-paragraph":
        return (
          <Paragraph content={block.content}
                     attachments={block.attachments}
                     notes={notes}
                     reference={reference}
                     narrative={narrative}
                     allImages={allImages}
                     preformatted={block.type === 'preformatted-paragraph'}
                     meta={block.type === 'meta-paragraph'}
                     key={idx}
                     embeddedLayout={ embeddedLayout }
                     />
        );
      case "pull-quote":
        return (<PullQuote content={block.content}
                           id={block.id}
                           key={idx} />);
      case "major-divider":
        return (<Divider type="major" key={idx} />);
      case "minor-divider":
        return (<Divider type="minor" key={idx} />);
      case "major-subhead":
        return (<MajorSubhead content={block.content} key={idx} />);
      case "minor-subhead":
        return (<MinorSubhead content={block.content} key={idx} />);
      case "image":
        return (<Image content={block.content}
                       fullWidth={true}
                       reference={reference}
                       allImages={allImages}
                       key={idx} />);
      case "image-gallery":
        return (<ImageGallery content={block.content}
                              reference={reference}
                              allImages={allImages}
                              key={idx} />);
      case "blockquote":
        return (<Blockquote contentBlocks={block.content.contentBlocks}
                            notes={notes}
                            reference={reference}
                            allImages={allImages}
                            key={idx} />);
      case "video":
        return (<Video content={block.content} key={idx} />);
      case "audio-playlist":
        return (<AudioPlayerList content={block.content} key={idx} />);
      case "offset":
        return (<Offset content={block.content} key={idx} />);
      case "simple-map":
        return (<SimpleMap content={block.content} key={idx} />);
      case "scroll-block":
        return (<ScrollingBlock leftSideContent={block.content.leftSideContent}
                                rightSideContent={block.content.rightSideContent}
                                notes={notes}
                                reference={reference}
                                allImages={allImages}
                                key={idx} />);
      case "custom-block":
        return (<CustomBlock content={block.content} key={idx} />);
      case "popup-jukebox":
        return (<JukeboxCollection content={block.content}
                                   launchPopupJukebox={narrative.launchPopupJukebox}
                                   key={idx} />);
      default:
        return null;
    }
  });
}

export default (props) => {
  const content = assembleContent(props.contentBlocks,
                                  props.notes, props.reference, props.narrative, props.allImages, props.embeddedLayout);

  return (
    <>
      {content}
    </>
  )
}
