import React from "react";
import { graphql } from "gatsby";
import "babel-polyfill";
import { marked } from "marked";

import ExhibitChildLayout from "../components/layout/layout-exhibit-child";

import Helmet from "react-helmet";

import PageContainer from "../components/layout/page-container";
import PageSection from "../components/layout/page-section";

import ContentGroup from "../components/narratives/content-group";
import CitedArtistsBlock from "../components/narratives/cited-artists";
import CitedVenuesBlock from "../components/narratives/cited-venues";
import FootnotesBlock from "../components/narratives/footnotes-block";

import PopupJukebox from "../components/narratives/popup-jukebox/popup-jukebox";

import "../../static/vendor/fancybox/jquery.fancybox.min.css";

class Narrative extends React.Component {
  constructor({ data }) {
    super();

    this.launchPopupJukebox = this.launchPopupJukebox.bind(this);

    this.data = data;
    this.state =  { data: data,
                    jukeboxVideo: null,
                  };
  }

   async componentDidMount() {
    var loadScript = require('simple-load-script');
    await loadScript('/vendor/jquery/jquery-3.2.1.min.js', { inBody: true });
    loadScript('/vendor/fancybox/jquery.fancybox.min.js', { inBody: true });
  }

  launchPopupJukebox(video) {
    this.setState({jukeboxVideo: video});
  }

  headerImageCaption(image) {
    let caption = '', credit = '';

    if (image.node.caption && image.node.caption.length > 0) {
      caption = marked(image.node.caption);
      caption = caption.replace('<p>', '');
      caption = caption.replace('</p>', '');
    }
    if (image.node.credit && image.node.credit.length > 0) {
      credit = marked(image.node.credit);
      credit = credit.replace('<p>', '');
      credit = credit.replace('</p>', '');
    }

    if (caption.length > 0 || credit.length > 0) {
      return (
        <div className="narrative-header-image__text-block">
          {(caption.length > 0) ? (
            <span className="narrative-header-image__caption"
                  dangerouslySetInnerHTML={{__html: caption}} />
          ) : null}
          {(credit.length > 0) ? (
            <span className="narrative-header-image__credit"
                  dangerouslySetInnerHTML={{__html: credit}} />
          ) : null}
        </div>
      );
    }

    return null;
  }

  render() {
    const allArtists = this.state.data.allArtistsJson.edges;
    const allVenues = this.state.data.allVenuesJson.edges;
    const allImages = this.state.data.allImagesJson.edges;

    const post = this.state.data.narrativesJson;
    const content = (<ContentGroup contentBlocks={post.contentBlocks}
                                  allImages={allImages}
                                  notes={post.notes}
                                  reference={post.reference}
                                  narrative={this} />);


    const featuredImage = this.data.allImagesJson.edges.filter(({ node }) => {
      return node.reference === post.featuredImage;
    })[0];

    let headerImage = null;
    if (post.headerImage && post.headerImage.length > 0) {
      headerImage = this.data.allImagesJson.edges.filter(({ node }) => {
        return node.reference === post.headerImage;
      })[0];
    }

    const citedArtists = allArtists.filter((artist) => {
      if (post.artists) {
        return post.artists.includes(artist.node.reference);
      }

      return false;
    });
    const citedVenues = allVenues.filter((venue) => {
      if (post.venues) {
        return post.venues.includes(venue.node.reference);
      }

      return false;
    });

    return (
      <ExhibitChildLayout exhibit={this.data.exhibit}>
        <PageContainer
          bottomPadding="lg"
        >
          <Helmet>
            <title>{post.title}</title>
            <meta name="title" content={post.title} />
            <meta name="description" content={post.blurb} />

            <meta property="og:type" content="article" />
            <meta property="og:url" content={`https://local-memory.org/${this.data.exhibit.reference}/narratives/${post.reference}`} />
            <meta property="og:title" content={post.title} />
            <meta property="og:description" content={post.blurb} />
            {(featuredImage) ? (
              <meta property="og:image" content={`https://local-memory.org/images/content/${featuredImage.node.fullPath}`} />
            ) : null}

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={`https://local-memory.org/${this.data.exhibit.reference}/narratives/${post.reference}`} />
            <meta property="twitter:title" content={post.title} />
            <meta property="twitter:description" content={post.blurb} />
            {(featuredImage) ? (
              <meta property="twitter:image" content={`https://local-memory.org/images/content/${featuredImage.node.fullPath}`} />
            ) : null}
          </Helmet>
          <article>
            {(headerImage) ? (
              <PageSection background="gray">
                <div className="narrative-header-image">
                  <img src={`/images/content/${headerImage.node.fullPath}`} alt={headerImage.node.alt} />
                  {this.headerImageCaption(headerImage)}
                </div>
              </PageSection>
            ) : null}
            <PageSection background="dark-accent">
              <h1 className="page-title">{post.title}</h1>
            </PageSection>
            <PageSection background="light-accent" topPadding="sm" bottomPadding="sm">
              <div className="narrative-meta-block">
                {(post.summary.length > 0) ? (
                  <div
                    className="narrative-meta-block__summary"
                    dangerouslySetInnerHTML={{__html: marked(post.summary)}}
                  />
                ) : null}
                <div className="narrative-meta-block__details">
                  <table>
                    <tbody>
                      {post.metaDetails.map((line, idx) => (
                        <tr key={idx}>
                          <th scope="row">{line.label}</th>
                          <td>{line.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </PageSection>
            <PageSection topPadding="md">
              <div className="narrative-block">
                {content}
              </div>

              <CitedArtistsBlock citedArtists={citedArtists} />
              <CitedVenuesBlock citedVenues={citedVenues} />

              <FootnotesBlock footnotes={post.notes} />
            </PageSection>
          </article>

          <PopupJukebox
            video={this.state.jukeboxVideo}
            narrative={this} />
        </PageContainer>
      </ExhibitChildLayout>
    );
  }
}

export default Narrative;

export const query = graphql`
  query NarrativeQuery($slug: String!, $exhibit: String!) {
    narrativesJson(reference: {eq: $slug }) {
      reference
      title
      summary
      blurb
      featuredImage
      headerImage
      metaDetails {
        label
        value
      }
      contentBlocks {
        type
        content {
          reference
          text
          galleryName
          videoId
          service
          caption
          videos {
            caption
            service
            videoId
            clickthrough
          }
          tracks {
            title
            slug
            path
            format
          }
          images {
            reference
          }
          contentBlocks {
            type
            content {
              text
            }
            attachments {
              type
              content {
                text
                reference
              }
            }
          }
          leftSideContent {
            contentBlocks {
              type
              id
              content {
                text
                reference
                center
                dragControl
                zoomControl
                zoom
                minZoom
                maxZoom
                markers {
                  position
                }
              }
            }
          }
          rightSideContent {
            contentBlocks {
              type
              content {
                reference
                contentBlocks {
                  type
                  content {
                    text
                  }
                }
                text
              }
            }
          }
        }
        attachments {
          type
          exhibit
          target
          content {
            reference
            text
            videos {
              caption
              service
              videoId
              clickthrough
            }
          }
        }
      }
      notes {
        reference
        text
      }
      artists
      venues
    },
    exhibit: exhibitsJson(reference: {eq: $exhibit}) {
      reference
      short_title
      subtitle
    },
    allImagesJson {
      edges {
        node {
          reference
          fullPath
          thumbPath
          alt
          caption
          credit
        }
      }
    },
    allArtistsJson {
      edges {
        node {
          reference
          canonical_name
        }
      }
    },
    allVenuesJson {
      edges {
        node {
          reference
          canonical_name
        }
      }
    }
  }
`;
