import React from 'react';

class DataTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={`econ-viz-data-table ${(this.props.big) ? 'big': ''}`}>
        {(this.props.header) ? (
          <p className="data-table__header">
            {this.props.header}
          </p>
        ) : null}
        <ul>
          <li>
            <span className="swatch white"></span>{' '}
            <span className="label">White:</span>{' '}
            {this.props.dataset['white']}
          </li>
          <li>
            <span className="swatch black"></span>{' '}
            <span className="label">Black:</span>{' '}
            {this.props.dataset['black']}
          </li>
          <li>
            <span className="swatch latino"></span>{' '}
            <span className="label">Latino:</span>{' '}
            {this.props.dataset['latino']}
          </li>
          <li>
            <span className="unknown swatch"></span>{' '}
            <span className="label">Unknown:</span>{' '}
            {this.props.dataset['unknown']}
          </li>
        </ul>
      </div>
    );
  }
}

export default DataTable;